import {push} from "connected-react-router";
import moment from 'moment';
import React, {Component} from 'react';
import {connect} from "react-redux";
import PageNotFound from "../PageNotFound/PageNotFound";
import {getActivity, getMostPopularActivities} from "../_actions/activities";
import ActivityService from "../_services/activities";
import {getTranslations} from "../_services/translations";

import './Activity.css';

class Activity extends Component {

    componentDidMount() {
        const {dispatch, match: {params: {activityId} = {}} = {}} = this.props;
        dispatch(getActivity(activityId));
        dispatch(getMostPopularActivities());
    }

    openActivity = (activity) => {
        const {dispatch} = this.props;
        return dispatch(push(`/activities/${activity.id}`));
    };

    render() {
        const {
            language,
            activities: {isFetching, docs: activities, popular = []},
            match: {params: {activityId} = {}} = {}
        } = this.props;

        // TODO - add loader
        if (isFetching || !activities) {
            return null;
        }

        const activity = activities.find(activity => activity.id === activityId);

        if (!activity) {
            return <PageNotFound/>;
        }

        const t = getTranslations(language);

        if (!activity.languages.includes(language)) {
            return "We are sorry, activity not available in your language.";
        }

        return (
            <div className="Activity">
                <div className="row">
                    <div className="col-md-3">
                        <div className="Activities-popular">
                            <h2>MOST POPULAR ACTIVITIES</h2>

                            {popular.map((activity, index) => (
                                <div
                                    key={index}
                                    className="Activities-recent-item"
                                    onClick={() => this.openActivity(activity)}
                                >
                                    {activity.image && (
                                        <img
                                            src={activity.image}
                                            alt={ActivityService.getTitle(activity, language)}
                                            width="100%"
                                            height="auto"
                                        />
                                    )}
                                    {!activity.image && (
                                        <div className="Activity-no-image" onClick={() => this.openActivity(activity)}>
                                            <h1>HOMELESSNESS</h1>
                                        </div>
                                    )}
                                    <h3>{ActivityService.getTitle(activity, language)}</h3>
                                    <p>{moment(activity.createdAt).utc().format('DD MMM. YYYY').replace('May.', 'May')}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="Activity-details">
                            <span>{moment(activity.createdAt).utc().format('DD MMM. YYYY').replace('May.', 'May')}</span>
                            <span className="dot"></span>
                            <span>by {activity.author.name}</span>
                            <span className="dot"></span>
                            <span className="Activity-details-type">{t(activity.type.toUpperCase())}</span>

                            <h1>{ActivityService.getTitle(activity, language)}</h1>
                            <img src={activity.image} alt={ActivityService.getTitle(activity, language)} width="100%"
                                 height="auto"/>
                            <div className="Activity-content"
                                 dangerouslySetInnerHTML={{__html: activity.text[language]}}></div>

                            <a className="Activity-back" href="/activities">&lt; ALL ACTIVITIES</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    activities: state.activities,
    language: state.ui.language
});

export default connect(mapStateToProps)(Activity);