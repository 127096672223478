import uiConstants from '../_constants/ui';
import UIService from '../_services/ui';

const initialUIState = {
    language: UIService.getLanguage()
};

export function ui(state = initialUIState, action) {
    switch (action.type) {
        case uiConstants.LANGUAGE_CHANGED:
            return {
                ...state,
                language: action.language
            };
        default:
            return state;
    }
}
