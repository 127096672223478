import uiConstants from '../_constants/ui';
import UIService from '../_services/ui';

export function changeLanguage(language="en") {
    return async dispatch => {
        UIService.setLanguage(language);
        return dispatch({
            type: uiConstants.LANGUAGE_CHANGED,
            language
        });
    };
}
