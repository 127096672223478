import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {user} from './user';
import {activities} from './activities';
import {ui} from './ui';

const rootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        user,
        activities,
        ui
    });

export default rootReducer;
