import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getTranslations } from "../_services/translations";

import './Publications.css';

class Publications extends Component {

    english = () => (
        <>
            <div className="Section-group">
                <h2>2024</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Greiner, P. (2024)
                        </div>
                        <div className="col-8">
                            Dosta toga sam prošla… više loših stvari nego dobrih u životu” – ženski putevi u beskućništvo u hrvatskom kontekstu. Revija za sociologiju, 54(1), 63–89.<br/>
                            <a href="https://hrcak.srce.hr/ojs/index.php/rzs/article/view/27600" target="__blank" rel="noopener noreferrer">
                            https://hrcak.srce.hr/ojs/index.php/rzs/article/view/27600
                            </a>
                        </div>
                    </div>
                </div>
                <h2>2023</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Marinović Golubić, M., Sakić, S. i Šikić-Mićanović, L. (2023)
                        </div>
                        <div className="col-8">
                            From Free to Forced - Exploring Mobility among People Experiencing Homelessness. Sociologija i prostor, 61 (1 (226)), 123-142.<br/>
                            <a href="https://doi.org/10.5673/sip.61.1.6" target="__blank" rel="noopener noreferrer">
                                https://doi.org/10.5673/sip.61.1.6
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Anić, J. R.; Trbojević, F. (2023)
                        </div>
                        <div className="col-8">
                            „Božja toplina daje mi snagu“. Uloga vjere u životu osoba u beskućništvu: istraživanje iz Hrvatske // Crkva u svijetu, 58 (2023), 1; 54-76 doi:10.34075/cs.58.1.3
                        </div>
                    </div>
                </div>
                <h2>2022</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Greiner, Paula (2022)
                        </div>
                        <div className="col-8">
                            „Iskustva i strategije žena u uličnom beskućništvu u Zagrebu“, Revija za sociologiju, 52(3), str. 301–330. <br />
                            <a href='https://hrcak.srce.hr/ojs/index.php/rzs/article/view/21404' target="__blank" rel="noopener noreferrer">
                                https://hrcak.srce.hr/ojs/index.php/rzs/article/view/21404
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Trbojević, Filip (2022)
                        </div>
                        <div className="col-8">
                            The Social Inclusion of People Experiencing Homelessness: A View from Different Perspectives, 2022., master thesis, Faculty of Law, Department of Social Work, Zagreb
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Greiner, P., Sakić, S. (2022)
                        </div>
                        <div className="col-8">
                            "Utjecaj koronakrize na osobe u statusu beskućnika u Zagrebu", U: COVID-19 u humanističkoj perspektivi, ur: Katarinčić, I.; Marković, J.; Prica, I., Vukušić, A. Institut za etnologiju i folkloristiku. str. 165-185.
                        </div>
                    </div>
                </div>
                <h2>2021</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Anić, J. R., Trbojević, F. (2021)
                        </div>
                        <div className="col-8">
                            You Are a Foreigner in Your Own City and in Your Own Country; I Mean, You Are Literally Like a Refugee”: The Role of Religion in the Lives of Homeless Women – (Hidden) Migrants // Women's Religious Voices. Migration, Culture and (Eco)Peacebuilding / Furlan Štante, N., Bjelica, M., Anić, J. R. (ur.). Münster: LIT Verlag, 2021. str. 15-31
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Drilling, M., Mühlethaler, E., Iyadurai, G., Dittmann, J. (2021)
                        </div>
                        <div className="col-8">
                            Homelessness Research and Policy in Switzerland – A First Country Report Embedded in the UN and European Framework. European Journal of Homelessness (15) 1.<br />
                            <a href="https://www.feantsaresearch.org/en/publications/european-journal-of-homelessness"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.feantsaresearch.org/en/publications/european-journal-of-homelessness
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Greiner, P. (2021)
                        </div>
                        <div className="col-8">
                            Trajectories to the Coast and Islands: The Meanings of These Journeys Among People Experiencing Homelessness.<br />
                            MEDITERRANEAN — IMPRESSIONS, CONCEPTS, STORIES Mediterranean Issues, Book 3 / Jurčević, K.; Kaliterna Lipovčan, Lj.; Medić, R.; Ramljak, O.(ur.). Zagreb: Institute of Social Sciences Ivo Pilar ; VERN' Group ; Croatian Academy of Sciences and Arts, Scientific Council of Anthropological Research (HAZU), 2021. str. 121-131<br />
                            <a href="https://www.yumpu.com/en/document/read/65592646/mic-vis-2020-proceedings"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.yumpu.com/en/document/read/65592646/mic-vis-2020-proceedings
                            </a>
                        </div>
                    </div>
                </div>
                <h2>2020</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Drilling, M., Mühlethaler, E.,  Iyadurai, G. (2020)
                        </div>
                        <div className="col-8">
                            Drilling, M., Mühlethaler, E.,  Iyadurai, G. (2020). Homelessness. First Country Report Switzerland.<br />
                            <a href="https://www.researchgate.net/publication/338633912_Homelessness_First_Country_Report_Switzerland"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.researchgate.net/publication/338633912_Homelessness_First_Country_Report_Switzerland
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Sakić S., Stelko, S. (2020)
                        </div>
                        <div className="col-8">
                            Šikić-Mićanović, L., Sakić S., Stelko, S. (2020.) Kvaliteta usluga za beskućnike: prikaz trenutnog stanja i izazova u Hrvatskoj. [The Quality of Homeless Services: A Review of Current Status and Challenges in Croatia.]<br />
                            <i>Revija za socijalnu politiku</i>, 27 (2020), 3; 233-248.<br />
                            <a href="http://www.rsp.hr/ojs2/index.php/rsp/article/viewFile/1693/1481"
                                target="__blank" rel="noopener noreferrer"
                            >
                                http://www.rsp.hr/ojs2/index.php/rsp/article/viewFile/1693/1481
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Sakić S., Stelko, S. (2020)
                        </div>
                        <div className="col-8">
                            Šikić-Mićanović, L., Stelko, S., Sakić S. (2020) Who else needs protection? Reflecting on Researcher Vulnerability in Sensitive Research. Societies 2020, 10, 3.<br />
                            <a href="https://www.mdpi.com/2075-4698/10/1/3/htm"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.mdpi.com/2075-4698/10/1/3/htm
                            </a><br /><br />
                            also available in:<br />
                            Hilário, A.P., & Augusto; F.R. (Eds.) (2020) <i>Practical and Ethical Dilemmas in Researching Sensitive Topics with Populations Considered Vulnerable</i><br />
                            doi: 10.3390/books978-3-03943-395-7<br />
                            <a href="https://www.mdpi.com/books/pdfview/book/3052"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.mdpi.com/books/pdfview/book/3052
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    german = () => (
        <>
            <div className="Section-group">
                <h2>2023</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Marinović Golubić, M., Sakić, S. i Šikić-Mićanović, L. (2023)
                        </div>
                        <div className="col-8">
                            From Free to Forced - Exploring Mobility among People Experiencing Homelessness. Sociologija i prostor, 61 (1 (226)), 123-142.<br/>
                            <a href="https://doi.org/10.5673/sip.61.1.6" target="__blank" rel="noopener noreferrer">
                                https://doi.org/10.5673/sip.61.1.6
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Anić, J. R.; Trbojević, F. (2023)
                        </div>
                        <div className="col-8">
                            „Božja toplina daje mi snagu“. Uloga vjere u životu osoba u beskućništvu: istraživanje iz Hrvatske // Crkva u svijetu, 58 (2023), 1; 54-76 doi:10.34075/cs.58.1.3
                        </div>
                    </div>
                </div>
                <h2>2022</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Greiner, Paula (2022)
                        </div>
                        <div className="col-8">
                            „Iskustva i strategije žena u uličnom beskućništvu u Zagrebu“, Revija za sociologiju, 52(3), str. 301–330. <br />
                            <a href='https://hrcak.srce.hr/ojs/index.php/rzs/article/view/21404' target="__blank" rel="noopener noreferrer">
                                https://hrcak.srce.hr/ojs/index.php/rzs/article/view/21404
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Trbojević, Filip (2022)
                        </div>
                        <div className="col-8">
                            The Social Inclusion of People Experiencing Homelessness: A View from Different Perspectives, 2022., master thesis, Faculty of Law, Department of Social Work, Zagreb
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Greiner, P., Sakić, S. (2022)
                        </div>
                        <div className="col-8">
                            "Utjecaj koronakrize na osobe u statusu beskućnika u Zagrebu", U: COVID-19 u humanističkoj perspektivi, ur: Katarinčić, I.; Marković, J.; Prica, I., Vukušić, A. Institut za etnologiju i folkloristiku. str. 165-185.
                        </div>
                    </div>
                </div>
                <h2>2021</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Anić, J. R., Trbojević, F. (2021)
                        </div>
                        <div className="col-8">
                            You Are a Foreigner in Your Own City and in Your Own Country; I Mean, You Are Literally Like a Refugee”: The Role of Religion in the Lives of Homeless Women – (Hidden) Migrants // Women's Religious Voices. Migration, Culture and (Eco)Peacebuilding / Furlan Štante, N., Bjelica, M., Anić, J. R. (ur.). Münster: LIT Verlag, 2021. str. 15-31
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Drilling, M., Mühlethaler, E., Iyadurai, G., Dittmann, J. (2021)
                        </div>
                        <div className="col-8">
                            Homelessness Research and Policy in Switzerland – A First Country Report Embedded in the UN and European Framework. European Journal of Homelessness (15) 1.<br />
                            <a href="https://www.feantsaresearch.org/en/publications/european-journal-of-homelessness"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.feantsaresearch.org/en/publications/european-journal-of-homelessness
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Greiner, P. (2021)
                        </div>
                        <div className="col-8">
                            Trajectories to the Coast and Islands: The Meanings of These Journeys Among People Experiencing Homelessness.<br />
                            MEDITERRANEAN — IMPRESSIONS, CONCEPTS, STORIES Mediterranean Issues, Book 3 / Jurčević, K.; Kaliterna Lipovčan, Lj.; Medić, R.; Ramljak, O.(ur.). Zagreb: Institute of Social Sciences Ivo Pilar ; VERN' Group ; Croatian Academy of Sciences and Arts, Scientific Council of Anthropological Research (HAZU), 2021. str. 121-131<br />
                            <a href="https://www.yumpu.com/en/document/read/65592646/mic-vis-2020-proceedings"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.yumpu.com/en/document/read/65592646/mic-vis-2020-proceedings
                            </a>
                        </div>
                    </div>
                </div>
                <h2>2020</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Drilling, Mühlethaler, Iyadurai (2020)
                        </div>
                        <div className="col-8">
                            Drilling, M., Mühlethaler, E.,  Iyadurai, G. (2020). Homelessness. First Country Report Switzerland.<br />
                            <a href="https://www.researchgate.net/publication/338634020_Obdachlosigkeit_Erster_Landerbericht_Schweiz"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.researchgate.net/publication/338634020_Obdachlosigkeit_Erster_Landerbericht_Schweiz
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Sakić S., Stelko, S. (2020)
                        </div>
                        <div className="col-8">
                            Šikić-Mićanović, L., Sakić S., Stelko, S. (2020.) Kvaliteta usluga za beskućnike: prikaz trenutnog stanja i izazova u Hrvatskoj. [The Quality of Homeless Services: A Review of Current Status and Challenges in Croatia.]<br />
                            <i>Revija za socijalnu politiku</i>, 27 (2020), 3; 233-248.<br />
                            <a href="http://www.rsp.hr/ojs2/index.php/rsp/article/viewFile/1693/1481"
                                target="__blank" rel="noopener noreferrer"
                            >
                                http://www.rsp.hr/ojs2/index.php/rsp/article/viewFile/1693/1481
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Sakić S., Stelko, S. (2020)
                        </div>
                        <div className="col-8">
                            Šikić-Mićanović, L., Stelko, S., Sakić S. (2020) Who else needs protection? Reflecting on Researcher Vulnerability in Sensitive Research. Societies 2020, 10, 3.<br />
                            <a href="https://www.mdpi.com/2075-4698/10/1/3/htm"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.mdpi.com/2075-4698/10/1/3/htm
                            </a><br /><br />
                            also available in:<br />
                            Hilário, A.P., & Augusto; F.R. (Eds.) (2020) <i>Practical and Ethical Dilemmas in Researching Sensitive Topics with Populations Considered Vulnerable</i><br />
                            doi: 10.3390/books978-3-03943-395-7<br />
                            <a href="https://www.mdpi.com/books/pdfview/book/3052"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.mdpi.com/books/pdfview/book/3052
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    croatian = () => (
        <>
            <div className="Section-group">
                <h2>2024</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Greiner, P. (2024)
                        </div>
                        <div className="col-8">
                            Dosta toga sam prošla… više loših stvari nego dobrih u životu” – ženski putevi u beskućništvo u hrvatskom kontekstu. Revija za sociologiju, 54(1), 63–89.<br/>
                            <a href="https://hrcak.srce.hr/ojs/index.php/rzs/article/view/27600" target="__blank" rel="noopener noreferrer">
                            https://hrcak.srce.hr/ojs/index.php/rzs/article/view/27600
                            </a>
                        </div>
                    </div>
                </div>
                <h2>2023</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Marinović Golubić, M., Sakić, S. i Šikić-Mićanović, L. (2023)
                        </div>
                        <div className="col-8">
                            From Free to Forced - Exploring Mobility among People Experiencing Homelessness. Sociologija i prostor, 61 (1 (226)), 123-142.<br/>
                            <a href="https://doi.org/10.5673/sip.61.1.6" target="__blank" rel="noopener noreferrer">
                                https://doi.org/10.5673/sip.61.1.6
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Anić, J. R.; Trbojević, F. (2023)
                        </div>
                        <div className="col-8">
                            „Božja toplina daje mi snagu“. Uloga vjere u životu osoba u beskućništvu: istraživanje iz Hrvatske // Crkva u svijetu, 58 (2023), 1; 54-76 doi:10.34075/cs.58.1.3
                        </div>
                    </div>
                </div>
                <h2>2022</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Greiner, Paula (2022)
                        </div>
                        <div className="col-8">
                            „Iskustva i strategije žena u uličnom beskućništvu u Zagrebu“, Revija za sociologiju, 52(3), str. 301–330. <br />
                            <a href='https://hrcak.srce.hr/ojs/index.php/rzs/article/view/21404' target="__blank" rel="noopener noreferrer">
                                https://hrcak.srce.hr/ojs/index.php/rzs/article/view/21404
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Trbojević, Filip (2022)
                        </div>
                        <div className="col-8">
                        Socijalno uključivanje osoba u beskućništvu: pogled iz različitih perspektiva, 2022., diplomski rad, diplomski, Pravni fakultet, Studijski centar socijalnog rada, Zagreb / The Social Inclusion of People Experiencing Homelessness: A View from Different Perspectives, 2022., master thesis, Faculty of Law, Department of Social Work, Zagreb
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Greiner, P., Sakić, S. (2022)
                        </div>
                        <div className="col-8">
                            "Utjecaj koronakrize na osobe u statusu beskućnika u Zagrebu", U: COVID-19 u humanističkoj perspektivi, ur: Katarinčić, I.; Marković, J.; Prica, I., Vukušić, A. Institut za etnologiju i folkloristiku. str. 165-185.
                        </div>
                    </div>
                </div>
                <h2>2021</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Anić, J. R., Trbojević, F. (2021)
                        </div>
                        <div className="col-8">
                            You Are a Foreigner in Your Own City and in Your Own Country; I Mean, You Are Literally Like a Refugee”: The Role of Religion in the Lives of Homeless Women – (Hidden) Migrants // Women's Religious Voices. Migration, Culture and (Eco)Peacebuilding / Furlan Štante, N., Bjelica, M., Anić, J. R. (ur.). Münster: LIT Verlag, 2021. str. 15-31
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Drilling, M., Mühlethaler, E., Iyadurai, G., Dittmann, J. (2021)
                        </div>
                        <div className="col-8">
                            Homelessness Research and Policy in Switzerland – A First Country Report Embedded in the UN and European Framework. European Journal of Homelessness (15) 1.<br />
                            <a href="https://www.feantsaresearch.org/en/publications/european-journal-of-homelessness"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.feantsaresearch.org/en/publications/european-journal-of-homelessness
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Greiner, P. (2021)
                        </div>
                        <div className="col-8">
                            Trajectories to the Coast and Islands: The Meanings of These Journeys Among People Experiencing Homelessness.<br />
                            MEDITERRANEAN — IMPRESSIONS, CONCEPTS, STORIES Mediterranean Issues, Book 3 / Jurčević, K.; Kaliterna Lipovčan, Lj.; Medić, R.; Ramljak, O.(ur.). Zagreb: Institute of Social Sciences Ivo Pilar ; VERN' Group ; Croatian Academy of Sciences and Arts, Scientific Council of Anthropological Research (HAZU), 2021. str. 121-131<br />
                            <a href="https://www.yumpu.com/en/document/read/65592646/mic-vis-2020-proceedings"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.yumpu.com/en/document/read/65592646/mic-vis-2020-proceedings
                            </a>
                        </div>
                    </div>
                </div>
                <h2>2020</h2>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Drilling, M., Mühlethaler, E.,  Iyadurai, G. (2020)
                        </div>
                        <div className="col-8">
                            Drilling, M., Mühlethaler, E.,  Iyadurai, G. (2020). Homelessness. First Country Report Switzerland.<br />
                            <a href="https://www.researchgate.net/publication/338633912_Homelessness_First_Country_Report_Switzerland"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.researchgate.net/publication/338633912_Homelessness_First_Country_Report_Switzerland
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Sakić S., Stelko, S. (2020)
                        </div>
                        <div className="col-8">
                            Šikić-Mićanović, L., Sakić S., Stelko, S. (2020.) Kvaliteta usluga za beskućnike: Prikaz trenutnog stanja i izazova u Hrvatskoj.<br />
                            <i>Revija za socijalnu politiku</i>, 27 (2020), 3; 233-248.<br />
                            <a href="http://www.rsp.hr/ojs2/index.php/rsp/article/viewFile/1693/1481"
                                target="__blank" rel="noopener noreferrer"
                            >
                                http://www.rsp.hr/ojs2/index.php/rsp/article/viewFile/1693/1481
                            </a>
                        </div>
                    </div>
                </div>
                <div className="Section-group-content">
                    <div className="row">
                        <div className="col-4">
                            Šikić-Mićanović, L., Sakić S., Stelko, S. (2020)
                        </div>
                        <div className="col-8">
                            Šikić-Mićanović, L., Stelko, S., Sakić S. (2020) Who else needs protection? Reflecting on Researcher Vulnerability in Sensitive Research. Societies 2020, 10, 3.<br />
                            <a href="https://www.mdpi.com/2075-4698/10/1/3/htm"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.mdpi.com/2075-4698/10/1/3/htm
                            </a><br /><br />
                            dostupno i u:<br />
                            Hilário, A.P., & Augusto; F.R. (Eds.) (2020) <i>Practical and Ethical Dilemmas in Researching Sensitive Topics with Populations Considered Vulnerable</i><br />
                            doi: 10.3390/books978-3-03943-395-7<br />
                            <a href="https://www.mdpi.com/books/pdfview/book/3052"
                                target="__blank" rel="noopener noreferrer"
                            >
                                https://www.mdpi.com/books/pdfview/book/3052
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );


    render() {
        const { language } = this.props;
        const t = getTranslations(language);

        return (
            <div className="Publications">
                {/* HEADER SECTION */}
                <div className="Publications-header">
                    <div className="container">
                        <div className="Header-container">
                            <div>
                                <h1>{t('PUBLICATIONS').toUpperCase()}</h1>
                                <p>{t('PUBLICATIONS_SUBTITLE')}</p>
                            </div>
                            <div><a href="/">{t('HOME')}</a> / {t('PUBLICATIONS')}</div>
                        </div>
                    </div>
                </div>
                {/* HEADER SECTION END */}

                <div className="Publications-section">
                    <div className="container">
                        {language === "en" && this.english()}
                        {language === "de" && this.german()}
                        {language === "hr" && this.croatian()}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    language: state.ui.language,
})

export default connect(mapStateToProps)(Publications);