import React, {Component} from 'react';
import { connect } from 'react-redux';

import matthias from '../_images/team/matthias-drilling.jpg';
import esther from '../_images/team/esther-muehlethaler.jpg';
import gosalya from '../_images/team/gosalya-lyadurai.jpg';
import jadranka from '../_images/team/jadranka-rebeka-anic.jpg';
import joerg from '../_images/team/joerg-dittmann.jpg';
import lyn from '../_images/team/lynette-sikic-micanovic.jpg';
import marica from '../_images/team/marica-marinovic-golubic.jpg';
import matija from '../_images/team/matija-krizmanic.jpg';
import paula from '../_images/team/paula-greiner.jpg';
import stephanie from '../_images/team/stephanie-stelko.jpg';
import filip from '../_images/team/filip-trbojevic.jpg';
import suzana from '../_images/team/suzana-sakic.jpg';
import zsolt from '../_images/team/zsolt-temesvary.jpg';
import email from '../_images/email.svg';

import {getTranslations} from "../_services/translations";

import './Team.css';

class Team extends Component {

    render() {
        const {language} = this.props;
        const t = getTranslations(language);

        return (
            <div className="Team">
                {/* HEADER SECTION */}
                <div className="Team-header">
                    <div className="container">
                        <div className="Header-container">
                            <div>
                                <h1>{t('TEAM').toUpperCase()}</h1>
                                <p>{t('TEAM_SUBTITLE')}</p>
                            </div>
                            <div><a href="/">{t('HOME')}</a> / {t('TEAM')}</div>
                        </div>
                    </div>
                </div>
                {/* HEADER SECTION END */}

                <div className="Team-section">
                    <div className="container">
                        <div className="Section-group">
                            <h2>{t('TEAM_FROM_CROATIA')}</h2>
                            <div className="Section-group-content">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <img src={lyn} className="Section-group-image" alt="Lynette Šikić Mićanović" />
                                        <h3 className="Section-group-name">Lynette Šikić Mićanović</h3>
                                        <em className="Section-group-title">{t('TEAM_PRINCIPAL_INVESTIGATOR')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:lyn@pilar.hr" className="Section-group-email">lyn@pilar.hr</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={jadranka} className="Section-group-image" alt="Jadranka Rebeka Anić" />
                                        <h3 className="Section-group-name">Jadranka Rebeka Anić</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCH_ADVISER')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:rebeka.anic@pilar.hr" className="Section-group-email">rebeka.anic@pilar.hr</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={marica} className="Section-group-image" alt="Marica Marinović Golubić" />
                                        <h3 className="Section-group-name">Marica Marinović Golubić</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCH_ASSOCIATE_MARICA')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:marica.marinovic@pilar.hr" className="Section-group-email">marica.marinovic@pilar.hr</a>
                                        </div>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:mmarinovi@hrstud.hr" className="Section-group-email">mmarinovi@hrstud.hr</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={paula} className="Section-group-image" alt="Paula Greiner" />
                                        <h3 className="Section-group-name">Paula Greiner</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCH_ASSISTANT_PAULA')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:paula.greiner@pilar.hr" className="Section-group-email">paula.greiner@pilar.hr</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={matija} className="Section-group-image" alt="Matija Krizmanić" />
                                        <h3 className="Section-group-name">Matija Krizmanić</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCH_ASSISTANT_MATIJA')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:mkrizmanic@pilar.hr" className="Section-group-email">mkrizmanic@pilar.hr</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={stephanie} className="Section-group-image" alt="Stephanie Stelko" />
                                        <h3 className="Section-group-name">Stephanie Stelko</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCH_ASSISTANT')}</em>
                                        <p className="Section-group-special-text">{t('TEAM_EXTRA_TEXT_STEPHANIE')}</p>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={filip} className="Section-group-image" alt="Filip Trbojević" />
                                        <h3 className="Section-group-name">Filip Trbojević</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCH_ASSISTANT_FILIP')}</em>
                                        <p className="Section-group-special-text">{t('TEAM_EXTRA_TEXT_FILIP')}</p>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={suzana} className="Section-group-image" alt="Suzana Sakić" />
                                        <h3 className="Section-group-name">Suzana Sakić</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCH_ASSISTANT')}</em>
                                        <p className="Section-group-special-text">{t('TEAM_EXTRA_TEXT_SUZANA')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Section-group">
                            <h2>{t('TEAM_FROM_SWITZERLAND')}</h2>
                            <div className="Section-group-content">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <img src={matthias} className="Section-group-image" alt="Matthias Drilling" />
                                        <h3 className="Section-group-name">Matthias Drilling</h3>
                                        <em className="Section-group-title">{t('TEAM_PROJECT_LEADER')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:matthias.drilling@fhnw.ch" className="Section-group-email">matthias.drilling@fhnw.ch</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={esther} className="Section-group-image" alt="Esther Mühlethaler" />
                                        <h3 className="Section-group-name">Esther Mühlethaler</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCH_ASSOCIATE')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:esther.muehlethaler@fhnw.ch" className="Section-group-email">esther.muehlethaler@fhnw.ch</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={gosalya} className="Section-group-image" alt="Gosalya Iyadurai" />
                                        <h3 className="Section-group-name">Gosalya Iyadurai</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCH_ASSOCIATE_GOSALYA')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:gosalya.iyadurai@fhnw.ch" className="Section-group-email">gosalya.iyadurai@fhnw.ch</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={joerg} className="Section-group-image" alt="Joerg Dittmann" />
                                        <h3 className="Section-group-name">Joerg Dittmann</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCHER')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:joerg.dittmann@fhnw.ch" className="Section-group-email">joerg.dittmann@fhnw.ch</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <img src={zsolt} className="Section-group-image" alt="Zsolt Temesvary" />
                                        <h3 className="Section-group-name">Zsolt Temesvary</h3>
                                        <em className="Section-group-title">{t('TEAM_RESEARCHER')}</em>
                                        <div>
                                            <img src={email} className="Section-group-email-icon" alt="Email icon" />
                                            <a href="mailto:zsolt.temesvary@fhnw.ch" className="Section-group-email">zsolt.temesvary@fhnw.ch</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    language: state.ui.language,
})

export default connect(mapStateToProps)(Team);