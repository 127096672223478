import get from 'lodash/get';

class UserService {

    async login(username, password) {
        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username, password})
        });

        if (!response.ok) {
            throw new Error("Login failed!");
        }

        const res = await response.json();
        const {user, token} = res;

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
    }

    getToken() {
        return localStorage.getItem('token');
    }

    getUser() {
         const user = localStorage.getItem('user');
         if (!user) {
             return null;
         }
         return JSON.parse(user);
    }

    getUserName() {
        return get(this.getUser(), 'name', '');
    }

    isLoggedIn() {
        return this.getToken() !== null;
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }
}

export default new UserService();