import {push} from "connected-react-router";
import React, {Component} from 'react';
import {connect} from "react-redux";

import UserService from '../_services/user';

import './Login.css';

class Login extends Component {

    state = {
        username: '',
        password: '',
        usernameError: false,
        passwordError: false,
    }

    onChange = (field) => (e) => {
        this.setState({[field]: e.target.value});
    }

    onSubmit = (e) => {
        e.preventDefault();
        const {dispatch} = this.props;
        const {username, password} = this.state;

        UserService.login(username, password)
            .then(() => dispatch(push('/')))
            .catch(() => this.setState({usernameError: true, passwordError: true}));
    }

    render() {
        const {usernameError, passwordError} = this.state;

        return (
            <div className="Login">
                <div className="container">
                    <a href="/">
                        <h1>HOMELESSNESS</h1>
                    </a>
                    <div className="Login-form">
                        <h2>LOG IN</h2>
                        <form action="#" onSubmit={this.onSubmit}>
                            <input type="text" name="username" className={usernameError ? 'error': ''} placeholder="Username" onChange={this.onChange('username')} />
                            <input type="password" name="password" className={passwordError ? 'error': ''} placeholder="Password" onChange={this.onChange('password')} />
                            <input type="submit" value="LOG IN"></input>
                        </form>
                    </div>
                </div>

                <div className="Login-sidebar">
                    <div className="Login-sidebar-image"></div>
                </div>
            </div>
        )
    }
}

export default connect()(Login);