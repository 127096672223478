export const translations = {
    en: {
        // general
        HOME: "Home",
        ABOUT: "About",
        ACTIVITIES: "Activities",
        PUBLICATIONS: "Publications",
        TEAM: "Team",
        READ_MORE_BUTTON: "READ MORE",
        EDIT_BUTTON: "EDIT",
        DELETE_BUTTON: "DELETE",
        CONTACT_US_BUTTON: "CONTACT US",
        SWITZERLAND: "Switzerland",
        CROATIA: "Croatia",
        DELETE: "DELETE",
        EDIT: "EDIT",
        PUBLISH: "PUBLISH",
        PROJECT_ACTIVITY: "Project activity",
        RELATED_ACTIVITY: "Related activity",

        // 404 page
        PAGE_DOES_NOT_EXIST: "Page doesn't exist.",
        BACK_TO_HOME: "BACK TO HOME",

        // home page
        HOME_TITLE_EXPLORING: "EXPLORING",
        HOME_TITLE_HOMELESSNESS: "homelessness",
        HOME_SUBTITLE: "and Pathways to Social Inclusion",
        HOME_EXPLORE: "EXPLORE",
        HOME_ABOUT_PROJECT: "ABOUT PROJECT",
        HOME_WHAT_WE_DO: "What we do?",
        HOME_ABOUT_PROJECT_MAIN_TEXT: "This qualitative study seeks to break new ground in social research on homelessness by delivering a better understanding of the dimensions and impact of exclusion on people who are homeless in cities of Switzerland and Croatia.",
        HOME_SECONDARY_TEXT_BOLD: "A comparative approach",
        HOME_SECONDARY_TEXT_REST: "is of relevance because homelessness as a social phenomenon and as a manifestation of poverty takes different forms.",
        HOME_SECONDARY_TEXT_BOLD_2: "Inclusive strategies",
        HOME_SECONDARY_TEXT_REST_2: "need to be developed in a contextually-sensitive framework and based on scientific knowledge and reflection. Inclusion and exclusion are closely related. Social inclusion will be discussed within the theoretical frame of social exclusion. Contexts of homelessness and challenges will be compared.",
        HOME_GOALS: "GOALS",
        HOME_GOALS_SUBTITLE: "What do we want to achieve?",
        HOME_GOALS_1_TITLE: "'Insider' perspective",
        HOME_GOALS_1_TEXT: "To generate a new and emic perspective on homelessness, and to generate theory that is grounded in the realities of the participants’ daily life experiences based on homeless people’s “voices”",
        HOME_GOALS_2_TITLE: "Inclusion strategies",
        HOME_GOALS_2_TEXT: "To work out a differentiated landscape of inclusion strategies for professionals in the services for homeless people as well as for local authorities, NGOs, faith-based social services, communities and the state",
        HOME_GOALS_3_TITLE: "Cooperation and collaboration",
        HOME_GOALS_3_TEXT: "To bring actors/responsible persons/researchers (young/senior) from Croatia and Switzerland together for cooperation and collaboration and connect them to a wider European network of scientists working on homelessness",
        HOME_IMAGE_TITLE: "FIELDWORK",
        HOME_IMAGE_TEXT: "Since the beginning of the project, both research teams have been conducting ethnographic fieldwork in Basel, Bern, Zagreb and Split with people experiencing homelessness (rough sleepers and shelter users) as well as practitioners in homelessness services.<br/><br/>Methods guaranteeing anonymity and confidentiality include participant observation, fieldnotes, questionnaires, interviews and walk alongs.",
        HOME_ACTIVITIES: "ACTIVITIES",
        HOME_ACTIVITIES_SUBTITLE: "Latest news",
        HOME_ACTIVITIES_TEXT: "",
        HOME_SEE_ALL_ACTIVITIES: "SEE ALL ACTIVITIES",
        HOME_LIVES_MATTER_TITLE: "HOMELESS LIVES MATTER",
        HOME_LIVES_MATTER_TEXT: "<i>Never doubt that a small group of thoughtful, committed citizens can change the world; indeed, it's the only thing that ever has.</i><br/>(Margaret Mead)",
        HOME_PUBLICATIONS: "PUBLICATIONS",
        HOME_PUBLICATIONS_SUBTITLE: "Latest articles we published",
        HOME_PUBLICATIONS_TEXT: "",
        HOME_SEE_ALL_PUBLICATIONS: "SEE ALL PUBLICATIONS",
        HOME_MEET_THE_TEAM: 'MEET THE TEAM',
        HOME_MEET_THE_TEAM_SUBTITLE: 'Meet the research team',

        // about page
        ABOUT_SUBTITLE: "Project description and Partners",
        ABOUT_PROJECT: "ABOUT PROJECT",
        ABOUT_PROJECT_SUBTITLE: "What we do?",
        ABOUT_PROJECT_TEXT: "This project is called Exploring Homelessness and Pathways to Social Inclusion: A Comparative Study of Contexts and Challenges in Swiss and Croatian Cities and is financed by the Swiss National Science Foundation and the Croatian Science Foundation. This study aims to explore the everyday practices of people experiencing homelessness and how this knowledge could contribute to more effective solutions. It also involves research with other experts and professionals from various institutions and organisations in Swiss and Croatian cities.",
        ABOUT_WHO_WILL_CONDUCT: "Who will conduct the research?",
        ABOUT_WHO_WILL_CONDUCT_TEXT: "This is a joint research project involving teams from two countries. The Swiss research team is led by Prof. Dr. Matthias Drilling from the Institute for Social Planning, Organizational Change and Urban Development, University of Applied Sciences and Arts. Research in Croatia is carried out by research team members from the Institute of Social Science Ivo Pilar and the principal investigator of this research team is Dr. Lynette Šikić Mićanović",
        ABOUT_WHO_APPROVED: "Who approved this research project?",
        ABOUT_WHO_APPROVED_TEXT: "Besides anonymous reviewers from SNSF and CSF, this research was approved by the Ethics Committee at the Institute of Social Sciences Ivo Pilar.",
        ABOUT_WHAT_IS_INVOLVED: "What is involved in this research?",
        ABOUT_WHAT_IS_INVOLVED_TEXT: "In-depth interviews with people experiencing homelessness and professionals, questionnaires, participant observation and walk alongs in four cities: Zagreb, Split, Basel and Bern.",
        ABOUT_WHAT_IS_THE_PURPOSE: "What is the purpose of this research?",
        ABOUT_WHAT_IS_THE_PURPOSE_TEXT: "To understand the everyday lives of people experiencing homelessness and social exclusion from their perspectives. We are interested in these ‘first-hand experiences’ to gain valuable knowledge on how to include, support and help people in similar situations.",
        ABOUT_CITIES: "CITIES",
        ABOUT_YEARS: "YEARS",
        ABOUT_PARTICIPATING_ORGANIZATIONS: "PARTICIPATING ORGANIZATIONS",
        ABOUT_INTERVIEWS: "INTERVIEWS",
        ABOUT_SUPPORTERS: 'SUPPORTERS',
        ABOUT_SUPPORTERS_SUBTITLE: "It wouldn't be possible without them",
        ABOUT_PARTICIPATING_ORGANIZATIONS_TITLE: "PARTICIPATING ORGANIZATIONS",
        ABOUT_ORGANIZATIONS_INTERNATIONAL: "International",
        ABOUT_ORGANIZATIONS_SWITZERLAND: "Switzerland",
        ABOUT_ORGANIZATIONS_CROATIA: "Croatia",
        ABOUT_SELECTED_ACTIVITIES: "SELECTED PROJECT ACTIVITIES",
        ABOUT_SEE_ALL_ACTIVITIES: "SEE ALL ACTIVITIES",

        // activities page
        ACTIVITIES_MOST_POPULAR: "SELECTED ACTIVITIES",
        ACTIVITIES_SUBTITLE: "Project uploads and progress",
        ACTIVITY_NEW_MANAGE_TRANSLATIONS: "Manage translations:",
        ACTIVITY_NEW_IMAGE_URL: "Image URL",
        ACTIVITY_NEW_TITLE: "Title",

        // publications page
        PUBLICATIONS_SUBTITLE: "Latest articles we published",

        // team page
        TEAM_SUBTITLE: "Meet the research team",
        TEAM_FROM_CROATIA: "Team from Croatia",
        TEAM_FROM_SWITZERLAND: "Team from Switzerland",
        TEAM_PRINCIPAL_INVESTIGATOR: "Principal Investigator of Croatian team",
        TEAM_RESEARCH_ADVISER: "Research Adviser",
        TEAM_RESEARCH_ASSOCIATE_MARICA: "Research Associate",
        TEAM_RESEARCH_ASSOCIATE_GOSALYA: "Research Associate",
        TEAM_RESEARCH_ASSOCIATE: "Research Associate",
        TEAM_RESEARCH_ASSISTANT: "Research Assistant",
        TEAM_RESEARCH_ASSISTANT_PAULA: "Research Assistant",
        TEAM_RESEARCH_ASSISTANT_FILIP: "Research Assistant",
        TEAM_RESEARCH_ASSISTANT_MATIJA: "Research Assistant",
        TEAM_PROJECT_LEADER: "Principal Investigator of Swiss team",
        TEAM_RESEARCHER: "Researcher",
        TEAM_EXTRA_TEXT_STEPHANIE: "(team member until December 2019)",
        TEAM_EXTRA_TEXT_FILIP: "(team member from September 2020 until December 2021)",
        TEAM_EXTRA_TEXT_SUZANA: "(team member until May 2022)",

        // footer
        FOOTER_PROJECT_NAME: "Exploring Homelessness and Pathways to Social Inclusion",
        FOOTER_PROJECT_DETAIL: "A comparative study in Switzerland and Croatia",
        FOOTER_PRIVACY_POLICY: "Privacy policy",
        FOOTER_USE_CONDITIONS: "Use conditions",
        FOOTER_TEAM_LOGIN: "Team login",
        FOOTER_TEAM_LOGOUT: "Logout",
    },
    de: {
        // general
        HOME: "Homepage",
        ABOUT: "Über uns",
        ACTIVITIES: "Aktivitäten",
        PUBLICATIONS: "Publikationen",
        TEAM: "Team",
        READ_MORE_BUTTON: "WEITERLESEN",
        EDIT_BUTTON: "BEARBEITEN",
        DELETE_BUTTON: "LÖSCHEN",
        CONTACT_US_BUTTON: "KONTAKTIERE UNS",
        SWITZERLAND: "Schweiz",
        CROATIA: "Kroatien",
        DELETE: "LÖSCHEN",
        EDIT: "BEARBEITEN",
        PUBLISH: "VERÖFFENTLICHEN",
        PROJECT_ACTIVITY: "Projekttätigkeit",
        RELATED_ACTIVITY: "Projektverwandte Tätigkeit",

        // 404 page
        PAGE_DOES_NOT_EXIST: "Seite existiert nicht.",
        BACK_TO_HOME: "ZURÜCK ZUR HOMEPAGE",

        // home page
        HOME_TITLE_EXPLORING: "",
        HOME_TITLE_HOMELESSNESS: "Obdachlosigkeit",
        HOME_SUBTITLE: "und Wege zur sozialen Inklusion",
        HOME_EXPLORE: "ERKUNDEN",
        HOME_ABOUT_PROJECT: "ÜBER DAS PROJEKT",
        HOME_WHAT_WE_DO: "Was wir tun?",
        HOME_ABOUT_PROJECT_MAIN_TEXT: "In Ergänzung zu den Aktivitäten der COST-Action zielt die Studie darauf ab, neue Wege in der Sozialforschung zum Thema Obdachlosigkeit zu gehen, die ein besseres Verständnis der Dimensionen und Auswirkungen von Ausgrenzung von obdachlosen Menschen in Schweizer und kroatischen Städten erschliessen.",
        HOME_SECONDARY_TEXT_BOLD: "Ein vergleichender Ansatz",
        HOME_SECONDARY_TEXT_REST: "ist von Bedeutung, da Obdachlosigkeit als soziales Phänomen und als Erscheinungsform von Armut unterschiedliche Formen annimmt.",
        HOME_SECONDARY_TEXT_BOLD_2: "Inklusionsstrategien",
        HOME_SECONDARY_TEXT_REST_2: "werden in einem kontextsensitiven Rahmen und auf der Grundlage wissenschaftlicher Erkenntnisse und Überlegungen entwickelt. Inklusion und Exklusion sind eng miteinander verbunden: Soziale Inklusion wird innerhalb des theoretischen Rahmens der sozialen Exklusion diskutiert. Kontexte von Obdachlosigkeit und Herausforderungen werden verglichen.",
        HOME_GOALS: "PROJEKTZIELE",
        HOME_GOALS_SUBTITLE: "Was wollen wir erreichen?",
        HOME_GOALS_1_TITLE: "'Insider'-Perspektive",
        HOME_GOALS_1_TEXT: "Eine neue und emische Perspektive auf Obdachlosigkeit und eine Theorie entwickeln, die auf den Stimmen und Realitäten der täglichen Lebenserfahrungen der Teilnehmer*innen basiert",
        HOME_GOALS_2_TITLE: "Inklusionsstrategien",
        HOME_GOALS_2_TEXT: "Erarbeitung einer differenzierten Landschaft von Inklusionsstrategien für Fachkräfte in den Diensten für wohnungslose Menschen sowie für lokale Behörden, NGOs, kirchliche Sozialdienste, Gemeinden und den Staat",
        HOME_GOALS_3_TITLE: "Kooperation & Zusammenarbeit",
        HOME_GOALS_3_TEXT: "Akteure/Verantwortliche/Forschende (Junior/Senior) aus Kroatien und der Schweiz zur Zusammenarbeit und Kooperation zusammenzubringen und sie mit einem breiteren europäischen Netzwerk von Wissenschaftler*innen, die sich mit Obdachlosigkeit befassen, verbinden",
        HOME_IMAGE_TITLE: "FELDFORSCHUNG",
        HOME_IMAGE_TEXT: "Während des ersten Jahres führten die beiden Forschungsteams in Basel, Bern, Zagreb und Split ethnografische Feldforschung mit Menschen durch, die von Obdachlosigkeit betroffen sind oder waren (Strassenobdachlose und Nutzende von Notunterkünften), sowie mit Praktizierenden in Institutionen der Obdachlosenhilfe.<br/><br/>Zu den Methoden, die Anonymität und Vertraulichkeit gewährleisten, gehörten Teilnehmende Beobachtung, Fragebögen, Interviews und Spaziergänge.\n",
        HOME_ACTIVITIES: "AKTIVITÄTEN",
        HOME_ACTIVITIES_SUBTITLE: "Aktuelle Neuigkeiten",
        HOME_ACTIVITIES_TEXT: "",
        HOME_SEE_ALL_ACTIVITIES: "ALLE AKTIVITÄTEN ANZEIGEN",
        HOME_LIVES_MATTER_TITLE: "DAS LEBEN VON OBDACHLOSEN MENSCHEN ZÄHLT!",
        HOME_LIVES_MATTER_TEXT: "<i>Zweifle nie daran, dass eine kleine Gruppe engagierter Menschen die Welt verändern kann - tatsächlich ist dies die einzige Art und Weise, in der die Welt jemals verändert wurde.</i><br/>(Margaret Mead)",
        HOME_PUBLICATIONS: "PUBLIKATIONEN",
        HOME_PUBLICATIONS_SUBTITLE: "Neueste Artikel, die wir veröffentlicht haben",
        HOME_PUBLICATIONS_TEXT: "",
        HOME_SEE_ALL_PUBLICATIONS: "ALLE VERÖFFENTLICHUNGEN ANZEIGEN",
        HOME_MEET_THE_TEAM: 'TRIFF DAS TEAM',
        HOME_MEET_THE_TEAM_SUBTITLE: 'Menschen, die es möglich machen',

        // about page
        ABOUT_SUBTITLE: "Projektbeschreibung und Partner",
        ABOUT_PROJECT: "ÜBER DAS PROJEKT",
        ABOUT_PROJECT_SUBTITLE: "Was wir tun?",
        ABOUT_PROJECT_TEXT: "Dieses Projekt trägt den Namen \"Obdachlosigkeit und Wege zur sozialen Inklusion: Eine vergleichende Studie über Kontexte und Herausforderungen in schweizerischen und kroatischen Städten”: und wird vom Schweizerischen Nationalfonds und vom Kroatischen Wissenschaftsfonds finanziert. Ziel dieser Studie ist es, die Alltagspraktiken von Menschen, die Obdachlosigkeit erleben, zu erforschen und zu untersuchen, wie dieses Wissen zu wirksameren und integrativen Lösungen beitragen kann. Sie umfasst auch Forschungsarbeiten mit anderen Experten und Fachleuten aus verschiedenen Institutionen und Organisationen in Schweizer und kroatischen Städten.",
        ABOUT_WHO_WILL_CONDUCT: "Wer führt diese Forschung durch?",
        ABOUT_WHO_WILL_CONDUCT_TEXT: "Es handelt sich um ein gemeinsames Forschungsprojekt, an dem Teams aus zwei Ländern beteiligt sind. Das Schweizer Forschungsteam wird von Prof. Dr. Matthias Drilling vom Institut für Sozialplanung, Organisationswandel und Stadtentwicklung der Fachhochschule Nordwestschweiz geleitet. Die Forschung in Kroatien wird von den Mitgliedern des Forschungsteams des Instituts für Sozialwissenschaften Ivo Pilar durchgeführt. Die leitende Forscherin dieses Forschungsteams ist Dr. Lynette Šikić-Mićanović.",
        ABOUT_WHO_APPROVED: "Wer hat dieses Forschungsprojekt genehmigt?",
        ABOUT_WHO_APPROVED_TEXT: "Neben anonymen Gutachter*innen des SNF und CSF wurde diese Forschung von der Ethikkommission des Instituts für Sozialwissenschaften Ivo Pilar genehmigt.",
        ABOUT_WHAT_IS_INVOLVED: "Was umfasst die Forschung?",
        ABOUT_WHAT_IS_INVOLVED_TEXT: "Ausführliche Interviews mit Fachpersonen und Menschen, die Obdachlosigkeit erleben; Fragebögen, teilnehmende Beobachtung und Stadtrundgänge in vier Städten: Zagreb, Split, Basel und Bern. Workshops mit Interessenvertreter*innen, einschliesslich Politiker*innen.",
        ABOUT_WHAT_IS_THE_PURPOSE: "Was ist der Zweck der Forschung?",
        ABOUT_WHAT_IS_THE_PURPOSE_TEXT: "Das Alltagsleben von Menschen, die Obdachlosigkeit und soziale Ausgrenzung erleben, aus ihrer Perspektive zu verstehen. Wir sind an \"Erfahrungen aus erster Hand\" interessiert, um wertvolles Wissen darüber zu gewinnen, wie man Menschen in ähnlichen Situationen einbeziehen, unterstützen und helfen kann.",
        ABOUT_CITIES: "STÄDTE",
        ABOUT_YEARS: "JAHRE",
        ABOUT_PARTICIPATING_ORGANIZATIONS: "INVOLVIERTE ORGANSIATIONEN",
        ABOUT_INTERVIEWS: "INTERVIEWS",
        ABOUT_SUPPORTERS: "UNTERSTÜTZER",
        ABOUT_SUPPORTERS_SUBTITLE: "Ohne sie wäre es nicht möglich",
        ABOUT_PARTICIPATING_ORGANIZATIONS_TITLE: "INVOLVIERTE ORGANSIATIONEN",
        ABOUT_ORGANIZATIONS_INTERNATIONAL: "Internationale",
        ABOUT_ORGANIZATIONS_SWITZERLAND: "Schweizerische",
        ABOUT_ORGANIZATIONS_CROATIA: "Kroatische",
        ABOUT_SELECTED_ACTIVITIES: "JÜNGSTE AKTIVITÄTEN",
        ABOUT_SEE_ALL_ACTIVITIES: "ALLE AKTIVITÄTEN ANZEIGEN",

        // activities page
        ACTIVITIES_MOST_POPULAR: "BELIEBTESTE AKTIVITÄTEN",
        ACTIVITIES_SUBTITLE: "Projektverlauf und Entwicklungen",
        ACTIVITY_NEW_MANAGE_TRANSLATIONS: "Übersetzungen verwalten:",
        ACTIVITY_NEW_IMAGE_URL: "Bild URL",
        ACTIVITY_NEW_TITLE: "Titel",

        // publications page
        PUBLICATIONS_SUBTITLE: "Neueste Artikel, die wir veröffentlicht haben",

        // team page
        TEAM_SUBTITLE: "Menschen, die es möglich machen",
        TEAM_FROM_CROATIA: "Team aus Kroatien",
        TEAM_FROM_SWITZERLAND: "Team aus der Schweiz",
        TEAM_PRINCIPAL_INVESTIGATOR: "Projektleiterin",
        TEAM_RESEARCH_ADVISER: "Wissenschaftliche Beraterin",
        TEAM_RESEARCH_ASSOCIATE_MARICA: "Wissenschaftliche Mitarbeiterin",
        TEAM_RESEARCH_ASSOCIATE_GOSALYA: "Wissenschaftliche Assistentin",
        TEAM_RESEARCH_ASSOCIATE: "Wissenschaftliche Mitarbeiterin",
        TEAM_RESEARCH_ASSISTANT: "Wissenschaftliche Assistentin",
        TEAM_RESEARCH_ASSISTANT_PAULA: "Wissenschaftliche Assistentin",
        TEAM_RESEARCH_ASSISTANT_FILIP: "Wissenschaftlicher Assistent",
        TEAM_RESEARCH_ASSISTANT_MATIJA: "Wissenschaftlicher Assistent",
        TEAM_PROJECT_LEADER: "Projektleiter",
        TEAM_RESEARCHER: "Forscher",
        TEAM_EXTRA_TEXT_STEPHANIE: "(Teammitglied bis Dezember 2019)",
        TEAM_EXTRA_TEXT_FILIP: "(Teammitglied von September 2020 bis Dezember 2021)",
        TEAM_EXTRA_TEXT_SUZANA: "(Teammitglied bis Kann 2022)",

        // footer
        FOOTER_PROJECT_NAME: "Obdachlosigkeit und Wege zur sozialen Inklusion",
        FOOTER_PROJECT_DETAIL: "Eine vergleichende Studie in der Schweiz und in Kroatien",
        FOOTER_PRIVACY_POLICY: "Datenschutzerklärung",
        FOOTER_USE_CONDITIONS: "Einsatzbedingungen",
        FOOTER_TEAM_LOGIN: "Team login",
        FOOTER_TEAM_LOGOUT: "Logout",
    },
    hr: {
        // general
        HOME: "Početna",
        ABOUT: "O nama",
        ACTIVITIES: "Aktivnosti",
        PUBLICATIONS: "Publikacije",
        TEAM: "Tim",
        READ_MORE_BUTTON: "PROČITAJ VIŠE",
        EDIT_BUTTON: "UREDI",
        DELETE_BUTTON: "OBRIŠI",
        CONTACT_US_BUTTON: "KONTAKTIRAJ NAS",
        SWITZERLAND: "Švicarska",
        CROATIA: "Hrvatska",
        DELETE: "OBRIŠI",
        EDIT: "UREDI",
        PUBLISH: "OBJAVI",
        PROJECT_ACTIVITY: "Projektna aktivnost",
        RELATED_ACTIVITY: "Povezana aktivnost",

        // 404 page
        PAGE_DOES_NOT_EXIST: "Stranica ne postoji.",
        BACK_TO_HOME: "NATRAG NA POČETNU",

        // home page
        HOME_TITLE_EXPLORING: "ISTRAŽIVANJE",
        HOME_TITLE_HOMELESSNESS: "beskućništva",
        HOME_SUBTITLE: "i puteva do socijalne inkluzije",
        HOME_EXPLORE: "ISTRAŽI",
        HOME_ABOUT_PROJECT: "O PROJEKTU",
        HOME_WHAT_WE_DO: "Što radimo?",
        HOME_ABOUT_PROJECT_MAIN_TEXT: "Ovom kvalitativnom studijom žele se steći novi uvidi unutar društvenih istraživanja o beskućništvu, i to boljim razumijevanjem utjecaja društvene isključenosti i njenih dimenzija na osobe koje proživljavaju iskustvo beskućništva u hrvatskim i švicarskim gradovima.",
        HOME_SECONDARY_TEXT_BOLD: "Komparativni pristup",
        HOME_SECONDARY_TEXT_REST: "koji podrazumijeva ova studija je važan jer beskućništvo kao društveni fenomen, te kao manifestacija siromaštva i isključenosti, poprima različite oblike.",
        HOME_SECONDARY_TEXT_BOLD_2: "Strategije inkluzije",
        HOME_SECONDARY_TEXT_REST_2: "trebaju biti razvijene na način da se u obzir uzme širi društveni kontekst i temeljiti se na znanstvenim spoznajama i refleksiji. Koncepti socijalne inkluzije i isključenosti su blisko povezani. Socijalna inkluzija će se razmatrati u sklopu teoretskog okvira o socijalnoj isključenosti. Istražit će se kontekst nastanka beskućništva i s njime povezani izazovi.",
        HOME_GOALS: "CILJEVI",
        HOME_GOALS_SUBTITLE: "Što želimo postići?",
        HOME_GOALS_1_TITLE: "Perspektiva 'iznutra'",
        HOME_GOALS_1_TEXT: "Prikazati novu i emsku perspektivu beskućništva te proizvesti teoriju koja je ukorijenjena u stvarnosti svakodnevnih iskustava sudionika, odnosno koja se temelji na „glasovima“ osoba koje su proživjele iskustvo beskućništva",
        HOME_GOALS_2_TITLE: "Strategije socijalnog uključivanja",
        HOME_GOALS_2_TEXT: "Predložiti raznovrsne strategije inkluzije namijenjene stručnjacima koji beskućnicima pružaju usluge, kao i lokalnim vlastima, organizacijama civilnog društva, vjerskim organizacijama koje pružaju socijalne usluge, zajednicama te zakonodavcima",
        HOME_GOALS_3_TITLE: "Međunarodna suradnja",
        HOME_GOALS_3_TEXT: "Potaknuti suradnju među istraživačima (mlađim i iskusnijim) iz Hrvatske i Švicarske te ih povezati sa širom europskom znanstvenom zajednicom posvećenom istraživanju beskućništva",
        HOME_IMAGE_TITLE: "TERENSKI RAD",
        HOME_IMAGE_TEXT: "Od početka projekta, oba istraživačka tima provode etnografski terenski rad  s osobama s iskustvom beskućništva (osobe “bez krova nad glavom” i osobe smještene u prihvatilišta/prenoćišta za beskućnike) u Baselu, Bernu, Zagrebu i Splitu, kao i sa stručnjacima u području socijalnih usluga za osobe u beskućništvu.<br/><br/>Korištene su metode koje jamče anonimnost i povjerljivost, a uključuju promatranje sa sudjelovanjem, upitnike, intervjue i provođenje vremena sa sudionicima istraživanja.",
        HOME_ACTIVITIES: "AKTIVNOSTI",
        HOME_ACTIVITIES_SUBTITLE: "Posljednje novosti",
        HOME_ACTIVITIES_TEXT: "",
        HOME_SEE_ALL_ACTIVITIES: "VIDI SVE AKTIVNOSTI",
        HOME_LIVES_MATTER_TITLE: "ŽIVOTI BESKUĆNIKA SU VAŽNI",
        HOME_LIVES_MATTER_TEXT: "<i>Nikada nemojte sumnjati u činjenicu da mala skupina promišljenih i zabrinutih građana može promijeniti svijet. Uistinu, dosad su ga jedino oni i mijenjali.</i><br/>(Margaret Mead)",
        HOME_PUBLICATIONS: "PUBLIKACIJE",
        HOME_PUBLICATIONS_SUBTITLE: "Posljednje objavljeni članci",
        HOME_PUBLICATIONS_TEXT: "",
        HOME_SEE_ALL_PUBLICATIONS: "SEE ALL PUBLICATIONS",
        HOME_MEET_THE_TEAM: "UPOZNAJ TIM",
        HOME_MEET_THE_TEAM_SUBTITLE: "Upoznajte istraživački tim",

        // about page
        ABOUT_SUBTITLE: "Opis projekta i partneri",
        ABOUT_PROJECT: "O PROJEKTU",
        ABOUT_PROJECT_SUBTITLE: "Što radimo?",
        ABOUT_PROJECT_TEXT: "Naziv projekta je “Exploring Homelessness and Pathways to Social Inclusion: A Comparative Study of Contexts and Challenges in Swiss and Croatian Cities” (“Istraživanje beskućništva i puteva do socijalne inkluzije: Komparativna studija konteksta i izazova u hrvatskim i švicarskim gradovima”),  i financiraju ga Švicarska nacionalna zaklada za znanost i Hrvatska zaklada za znanost. Cilj istraživanja je steći uvid u svakodnevne prakse beskućnika te na temelju toga znanja doprinijeti formiranju učinkovitijih rješenja. Osim osoba s iskustvom beskućništva, projekt uključuje stručnjake i profesionalce iz različitih institucija i organizacija u Švicarskoj i Hrvatskoj.",
        ABOUT_WHO_WILL_CONDUCT: "Tko će provoditi istraživanje?",
        ABOUT_WHO_WILL_CONDUCT_TEXT: "Ovo je zajednički projekt švicarskog istraživačkog tima, koji vodi prof. dr. sc. Matthias Drilling, Institute of Social Planning, Organizational Change and Urban Development, University of Applied Sciences and Arts Northwestern Switzerland u Baselu te hrvatskog istraživačkog tima s Instituta društvenih znanosti “Ivo Pilar” u Zagrebu, u kojem je glavna istraživačica dr. sc. Lynette Šikić-Mićanović.",
        ABOUT_WHO_APPROVED: "Tko je odobrio ovaj istraživački projekt?",
        ABOUT_WHO_APPROVED_TEXT: "Osim anonimnih ocjenjivača iz Švicarske nacionalne zaklade za znanost te Hrvatske zaklade za znanost, ovo je istraživanje odobrilo etičko povjerenstvo Instituta društvenih znanosti Ivo Pilar.",
        ABOUT_WHAT_IS_INVOLVED: "Što istraživanje uključuje?",
        ABOUT_WHAT_IS_INVOLVED_TEXT: "Istraživanje uključuje upitnike, intervjue (snimanje intervjua), promatranje sa sudjelovanjem i provođenje vremena s osobama koje imaju iskustvo beskućništva u četiri grada: Zagrebu, Splitu, Baselu i Bernu. Također, bit će provedeni intervju s dionicima u ovom području te održane radionice s njima, uključujući i donositelje političkih odluka.",
        ABOUT_WHAT_IS_THE_PURPOSE: "Koja je svrha istraživanja?",
        ABOUT_WHAT_IS_THE_PURPOSE_TEXT: "Svrha istraživanja je razumjeti i dobiti uvid u svakodnevicu ljudi s iskustvom beskućništva, kao i modele socijalne inkluzije, iz njihove perspektive. Zanima nas iskustvo “iz prve ruke” kako bismo stekli korisna znanja o tome kako uključiti, pružiti potporu i pomoći ljudima u sličnim situacijama.",
        ABOUT_CITIES: "GRADA",
        ABOUT_YEARS: "GODINE",
        ABOUT_PARTICIPATING_ORGANIZATIONS: "SUDJELUJUĆIH ORGANIZACIJA",
        ABOUT_INTERVIEWS: "INTERVJUA",
        ABOUT_SUPPORTERS: "FINANCIJSKA POTPORA",
        ABOUT_SUPPORTERS_SUBTITLE: "Bez njih ovo nebi bilo moguće",
        ABOUT_PARTICIPATING_ORGANIZATIONS_TITLE: "SURADNICI",
        ABOUT_ORGANIZATIONS_INTERNATIONAL: "Međunarodni",
        ABOUT_ORGANIZATIONS_SWITZERLAND: "Švicarska",
        ABOUT_ORGANIZATIONS_CROATIA: "Hrvatska",
        ABOUT_SELECTED_ACTIVITIES: "ODABRANE AKTIVNOSTI",
        ABOUT_SEE_ALL_ACTIVITIES: "VIDI SVE AKTIVNOSTI",

        // activities page
        ACTIVITIES_MOST_POPULAR: "ODABRANE AKTIVNOSTI",
        ACTIVITIES_SUBTITLE: "Napredak projekta",
        ACTIVITY_NEW_MANAGE_TRANSLATIONS: "Upravljanje prijevodima: ",
        ACTIVITY_NEW_IMAGE_URL: "URL slike",
        ACTIVITY_NEW_TITLE: "Naslov",

        // publications page
        PUBLICATIONS_SUBTITLE: "Posljednje objavljeni članci",

        // team page
        TEAM_SUBTITLE: "Upoznajte istraživački tim",
        TEAM_FROM_CROATIA: "Tim iz Hrvatske",
        TEAM_FROM_SWITZERLAND: "Tim iz Švicarske",
        TEAM_PRINCIPAL_INVESTIGATOR: "Voditeljica hrvatske dionice istraživanja",
        TEAM_RESEARCH_ADVISER: "Znanstvena savjetnica",
        TEAM_RESEARCH_ASSOCIATE_MARICA: "Znanstvena suradnica",
        TEAM_RESEARCH_ASSOCIATE_GOSALYA: "Asistentica",
        TEAM_RESEARCH_ASSOCIATE: "Asistentica",
        TEAM_RESEARCH_ASSISTANT: "Stručna suradnica",
        TEAM_RESEARCH_ASSISTANT_PAULA: "Asistentica",
        TEAM_RESEARCH_ASSISTANT_FILIP: "Stručni suradnik",
        TEAM_RESEARCH_ASSISTANT_MATIJA: "Stručni suradnik",
        TEAM_PROJECT_LEADER: "Voditelj švicarske dionice istraživanja",
        TEAM_RESEARCHER: "Znanstvenik",
        TEAM_EXTRA_TEXT_STEPHANIE: "(članica tima do prosinca 2019.)",
        TEAM_EXTRA_TEXT_FILIP: "(član tima od rujna 2020. do prosinca 2021.)",
        TEAM_EXTRA_TEXT_SUZANA: "(članica tima do svibnja 2022)",

        // footer
        FOOTER_PROJECT_NAME: "Istraživanje beskućništva i puteva do socijalne inkluzije",
        FOOTER_PROJECT_DETAIL: "Komparativna studija između Švicarske i Hrvatske",
        FOOTER_PRIVACY_POLICY: "Izjava o privatnosti",
        FOOTER_USE_CONDITIONS: "Uvjeti korištenja",
        FOOTER_TEAM_LOGIN: "Team login",
        FOOTER_TEAM_LOGOUT: "Odjava",
    }
}
