import {push} from "connected-react-router";
import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {EditorState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {Dropdown, DropdownButton} from "react-bootstrap";

import {createActivity} from "../_actions/activities";
import {languages} from '../_constants/ui';
import {getTranslations} from "../_services/translations";

import './ActivityNew.css';

class ActivityNew extends Component {

    state = {
        editorState: Object.keys(languages).reduce(
            (acc, cur) => {
                acc[cur] = EditorState.createEmpty();
                return acc;
            },
            {}
        ),
        image: '',
        languages: ['en'],
        currentLanguage: 'en',
        title: {},
        type: 'project_activity',
    };

    onEditorStateChange = (currentLanguage) => (newEditorState) => {
        this.setState({
            editorState: {
                ...this.state.editorState,
                [currentLanguage]: newEditorState
            }
        });
    };

    onPublish = () => {
        const {dispatch} = this.props;
        const {title: titles, image, type, editorState, languages} = this.state;

        let title = {}, text = {};
        languages.forEach(lang => {
            title[lang] = titles[lang];
            text[lang] = draftToHtml(convertToRaw(editorState[lang].getCurrentContent()));
        })

        const activity = {languages, image, type, title, text};

        dispatch(createActivity(activity)).then(() =>
            dispatch(push('/activities'))
        )
    }

    onDelete = () => {
        this.props.dispatch(push('/activities'))
    }

    onChange = (field) => (e) => {
        this.setState({[field]: e.target.value});
    }

    onChangeTitle = (lang) => (e) => {
        this.setState({
            title: {
                ...this.state.title,
                [lang]: e.target.value
            }
        });
    }

    onLanguageChange = (e) => {
        const language = e.target.name;
        const {languages, currentLanguage} = this.state;

        if (languages.length === 1 && languages.includes(language)) {
            return;
        }

        let newLanguages = [];
        if(languages.includes(language)) {
            newLanguages = languages.filter(lang => lang !== language)
        } else {
            newLanguages = languages.concat(language)
        };

        if (languages.includes(language)) {
            this.setState({languages: newLanguages})
            if (language === currentLanguage) {
                this.setState({currentLanguage: newLanguages[0]});
            }
        } else {
            this.setState({languages: newLanguages});
        }
    }

    onTabChange = (lang) => () => {
        const {languages} = this.state;
        if(!languages.includes(lang)) {
            return;
        }
        this.setState({currentLanguage: lang});
    }

    onSelectType = (type) => this.setState({type})

    render() {
        const {editorState, type, languages: selectedLanguages} = this.state;
        const {language} = this.props;
        const t = getTranslations(language);

        return (
            <div className="ActivityNew">
                <div className="container">

                    <div className="ActivityNew-buttons">
                        <button className="Btn-secondary" onClick={this.onDelete}>{t('DELETE')}</button>
                        <button className="Btn-primary" onClick={this.onPublish}>{t('PUBLISH')}</button>
                    </div>

                    <input type="text" className="Text-input" placeholder={t('ACTIVITY_NEW_IMAGE_URL')} onChange={this.onChange('image')} />

                    <DropdownButton id="type-dropdown" className="Dropdown-input shadow-none" title={t(type.toUpperCase())} onSelect={this.onSelectType}>
                        <Dropdown.Item eventKey="project_activity">{t('PROJECT_ACTIVITY')}</Dropdown.Item>
                        <Dropdown.Item eventKey="related_activity">{t('RELATED_ACTIVITY')}</Dropdown.Item>
                    </DropdownButton>

                    <div className="ActivityNew-translations">
                        <label>{t('ACTIVITY_NEW_MANAGE_TRANSLATIONS')}</label>

                        {Object.keys(languages).map((language, index) => (
                            <div className="Language-switch" key={index}>
                                <label className="Switch">
                                    <input type="checkbox" name={language} onChange={this.onLanguageChange} checked={selectedLanguages.includes(language)} />
                                    <span className="slider"></span>
                                </label>
                                <span>{language.toUpperCase()}</span>
                            </div>
                        ))}
                    </div>

                    {/*<div className="ActivityNew-Language-tabs">*/}
                    {/*    {Object.entries(languages).map(([langKey, langText], index) => (*/}
                    {/*        <label*/}
                    {/*            key={index}*/}
                    {/*            className={`Label ${langKey === currentLanguage ? 'active': ''} ${selectedLanguages.includes(langKey) ? '' : 'disabled'}`}*/}
                    {/*            onClick={this.onTabChange(langKey)}*/}
                    {/*        >*/}
                    {/*            {langText}*/}
                    {/*        </label>*/}
                    {/*    ))}*/}
                    {/*</div>*/}

                    {selectedLanguages.map((langKey, index) => (
                        <Fragment key={index}>
                            <label className="Label active">
                                {languages[langKey]}
                            </label>
                            <input
                                type="text"
                                className="Text-input"
                                placeholder={t('ACTIVITY_NEW_TITLE')}
                                onChange={this.onChangeTitle(langKey)}
                            />

                            <div className="ActivityNew-editor">
                                <Editor
                                    editorState={editorState[langKey]}
                                    wrapperClassName="Activity-wrapper"
                                    editorClassName="Activity-editor"
                                    onEditorStateChange={this.onEditorStateChange(langKey)}
                                />
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    language: state.ui.language,
});

export default connect(mapStateToProps)(ActivityNew);