import activitiesConstants from '../_constants/activities';
import ActivityService from '../_services/activities';

function getActivitiesRequest() {
    return {
        type: activitiesConstants.GET_REQUEST
    };
}

function getActivitiesSuccess(activities) {
    return {
        type: activitiesConstants.GET_SUCCESS,
        activities
    };
}

function getActivitiesFailure(error) {
    return {
        type: activitiesConstants.GET_FAILURE,
        error
    };
}

export function getActivities(page) {
    return async dispatch => {
        dispatch(getActivitiesRequest());
        return ActivityService.get(page)
            .then(activities => dispatch(getActivitiesSuccess(activities)))
            .catch(error => dispatch(getActivitiesFailure(error)));
    };
}

function getActivityRequest() {
    return {
        type: activitiesConstants.GET_ACTIVITY_REQUEST
    };
}

function getActivitySuccess(activity) {
    return {
        type: activitiesConstants.GET_ACTIVITY_SUCCESS,
        activity
    };
}

function getActivityFailure(error) {
    return {
        type: activitiesConstants.GET_ACTIVITY_FAILURE,
        error
    };
}

export function getActivity(activityId) {
    return async dispatch => {
        dispatch(getActivityRequest());
        return ActivityService.getOne(activityId)
            .then(activities => dispatch(getActivitySuccess(activities)))
            .catch(error => dispatch(getActivityFailure(error)));
    };
}

function getMostPopularActivitiesRequest() {
    return {
        type: activitiesConstants.GET_MOST_POPULAR_REQUEST
    };
}

function getMostPopularActivitiesSuccess(activities) {
    return {
        type: activitiesConstants.GET_MOST_POPULAR_SUCCESS,
        activities
    };
}

function getMostPopularActivitiesFailure(error) {
    return {
        type: activitiesConstants.GET_MOST_POPULAR_FAILURE,
        error
    };
}

export function getMostPopularActivities() {
    return async dispatch => {
        dispatch(getMostPopularActivitiesRequest());
        return ActivityService.getMostPopular()
            .then(activities => dispatch(getMostPopularActivitiesSuccess(activities)))
            .catch(error => dispatch(getMostPopularActivitiesFailure(error)));
    };
}

function createActivityRequest() {
    return {
        type: activitiesConstants.CREATE_REQUEST
    };
}

function createActivitySuccess(activity) {
    return {
        type: activitiesConstants.CREATE_SUCCESS,
        activity
    };
}

function createActivityFailure(error) {
    return {
        type: activitiesConstants.CREATE_FAILURE,
        error
    };
}

export function createActivity(activity) {
    return async dispatch => {
        dispatch(createActivityRequest());
        return ActivityService.create(activity)
            .then(activity => dispatch(createActivitySuccess(activity)))
            .catch(error => dispatch(createActivityFailure(error)));
    };
}

function deleteActivityRequest() {
    return {
        type: activitiesConstants.DELETE_REQUEST
    };
}

function deleteActivitySuccess(activityId) {
    return {
        type: activitiesConstants.DELETE_SUCCESS,
        activityId
    };
}

function deleteActivityFailure(error) {
    return {
        type: activitiesConstants.DELETE_FAILURE,
        error
    };
}

export function deleteActivity(activityId) {
    return async dispatch => {
        dispatch(deleteActivityRequest());
        return ActivityService.delete(activityId)
            .then(() => dispatch(deleteActivitySuccess(activityId)))
            .catch(error => dispatch(deleteActivityFailure(error)));
    };
}