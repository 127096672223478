import React, {Component} from 'react';
import { connect } from 'react-redux';

import {getTranslations} from "../_services/translations";
import UserService from '../_services/user';

import './Footer.css';

class Footer extends Component {

    state = {
        loggedIn: UserService.isLoggedIn()
    }

    logout = () => {
        UserService.logout();
        this.setState({loggedIn: false});
    }

    render() {
        const {language} = this.props;
        const {loggedIn} = this.state;
        const t = getTranslations(language);

        return (
            <>
                <footer className="Footer">
                    <div className="Footer-dots-left"></div>
                    <div className="Footer-content">
                        <h2 className="Footer-title">HOMELESSNESS</h2>
                        <div className="Footer-text">
                            <span>{t('FOOTER_PROJECT_NAME')}</span><br/>
                            <span>{t('FOOTER_PROJECT_DETAIL')}</span><br/>
                            <a href="/privacy-policy">
                                {t('FOOTER_PRIVACY_POLICY')}</a>
                            {' '} | {' '}
                            <a href="/use-conditions">
                                {t('FOOTER_USE_CONDITIONS')}
                            </a>
                            {' '} | {' '}
                            {loggedIn ?
                            (
                                <span className="Footer-logout" onClick={this.logout}>{t('FOOTER_TEAM_LOGOUT')}</span>
                            ) :
                                (
                                    <a href="/login">{t('FOOTER_TEAM_LOGIN')}</a>
                                )}
                        </div>
                    </div>
                    <div className="Footer-dots-right"></div>
                </footer>
                <div className="Copyright">
                    <h3 className="Copyright-content">Powered by <a href="https://code-lab.hr" target="_blank" rel="noopener noreferrer">CodeLab</a> & <a href="https://whitespace.hr" target="_blank" rel="noopener noreferrer">Whitespace</a></h3>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    language: state.ui.language,
})

export default connect(mapStateToProps)(Footer);