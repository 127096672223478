import React, {Component} from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {connect} from 'react-redux';

import {changeLanguage} from "../_actions/ui";
import {languages} from "../_constants/ui";
import userIcon from "../_images/user.svg";
import {getTranslations} from "../_services/translations";
import UserService from "../_services/user";

import './Header.css';

class Header extends Component {

    render() {
        const { path, language, dispatchChangeLanguage } = this.props;
        const t = getTranslations(language);

        return (
            <header className="Header">
                <Navbar bg="light" variant="light" expand="md" fixed="top">
                    <div className="container">
                        <Navbar.Brand href="/">HOMELESSNESS</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="/" active={path === '/'}>{t('HOME').toUpperCase()}</Nav.Link>
                                <Nav.Link href="/about" active={path === '/about'}>{t('ABOUT').toUpperCase()}</Nav.Link>
                                <Nav.Link href="/activities" active={path === '/activities'}>{t('ACTIVITIES').toUpperCase()}</Nav.Link>
                                <Nav.Link href="/publications" active={path === '/publications'}>{t('PUBLICATIONS').toUpperCase()}</Nav.Link>
                                <Nav.Link href="/team">{t('TEAM').toUpperCase()}</Nav.Link>
                                <NavDropdown title={language.toUpperCase()} onSelect={dispatchChangeLanguage} id="basic-nav-dropdown" className="Language-dropdown" alignRight>
                                    {Object.keys(languages).map((languageKey, index) => (
                                        <NavDropdown.Item
                                            key={index}
                                            eventKey={languageKey}
                                            active={language === languageKey}>
                                                {languages[languageKey]}
                                        </NavDropdown.Item>
                                    ))}
                                </NavDropdown>
                                {UserService.isLoggedIn() && (
                                    <Nav.Item>
                                        <div style={{marginTop: '8px', marginLeft: '10px'}}>
                                            <span>{UserService.getUserName()}</span>
                                            <img src={userIcon} width="14px" style={{marginLeft: '5px', marginBottom: '4px'}} alt={UserService.getUserName()} />
                                        </div>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </header>
        )
    }
}

const mapStateToProps = (state) => ({
    path: state.router.location.pathname,
    language: state.ui.language,
});

const mapDispatchToProps = ({
    dispatchChangeLanguage: changeLanguage,
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);