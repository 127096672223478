import {push} from "connected-react-router";
import moment from "moment";
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Title from '../_components/Title/Title';
import arrows from '../_images/arrows-explore.svg';
import collaboration from '../_images/swiss-croatian-collaboration.png';
import cooperation from '../_images/cooperation.svg';
import insiderPerspective from '../_images/insider-perspective.svg';
import strategies from '../_images/strategies.svg';
import dots from '../_images/dots.svg';
import dotsTestimonials from '../_images/dots-testimonials.svg';

import {getActivities} from "../_actions/activities";
import ActivityService from "../_services/activities";
import {getTranslations} from "../_services/translations";

import './Home.css';
import UserService from "../_services/user";

class Home extends Component {

    headerRef = React.createRef();
    scrollToHeader = () => window.scrollTo({left: 0, top: this.headerRef.current.offsetTop - 100, behavior: 'smooth'});

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getActivities(1));
    }

    openActivity = (activity) => {
        const { dispatch } = this.props;
        return dispatch(push(`/activities/${activity.id}`));
    };

    englishPublications = (t) => (
        <>
            <div className="col-md-4">
                <div className="Publications-section">
                    <div className="Publications-date">2021</div>

                    <h3 className="Publications-title">Homelessness Research and Policy in Switzerland – A First Country Report Embedded in the UN and European Framework. European Journal of Homelessness (15) 1.</h3>
                    <p>Drilling, M., Mühlethaler, E., Iyadurai, G., Dittmann, J.</p>

                    <Link to="/publications">{t('READ_MORE_BUTTON')} <span>&gt;</span></Link>
                </div>
            </div>
            <div className="col-md-4">
                <div className="Publications-section">
                    <div className="Publications-date">2020</div>

                    <h3 className="Publications-title">Homelessness. First Country Report Switzerland.</h3>
                    <p>Drilling, Mühlethaler, Iyadurai</p>

                    {/* TODO - add icon instead of &gt; */}
                    <Link to="/publications">{t('READ_MORE_BUTTON')} <span>&gt;</span></Link>
                </div>
            </div>
            <div className="col-md-4">
                <div className="Publications-section">
                    <div className="Publications-date">2020</div>

                    <h3 className="Publications-title">The Quality of Homeless Services: A Review of Current Status and Challenges in Croatia.</h3>
                    <p>Šikić-Mićanović, L., Sakić S., Stelko, S.</p>

                    <Link to="/publications">{t('READ_MORE_BUTTON')} <span>&gt;</span></Link>
                </div>
            </div>
        </>
    );

    germanPublications = (t) => (
      <>
          <div className="col-md-4 offset-md-2">
              <div className="Publications-section">
                  <div className="Publications-date">2021</div>

                  <h3 className="Publications-title">Homelessness Research and Policy in Switzerland – A First Country Report Embedded in the UN and European Framework. European Journal of Homelessness (15) 1.</h3>
                  <p>Drilling, M., Mühlethaler, E., Iyadurai, G., Dittmann, J.</p>

                  <Link to="/publications">{t('READ_MORE_BUTTON')} <span>&gt;</span></Link>
              </div>
          </div>
          <div className="col-md-4">
              <div className="Publications-section">
                  <div className="Publications-date">2020</div>

                  <h3 className="Publications-title">Homelessness. First Country Report Switzerland.</h3>
                  <p>Drilling, Mühlethaler, Iyadurai</p>

                  <Link to="/publications">{t('READ_MORE_BUTTON')} <span>&gt;</span></Link>
              </div>
          </div>
      </>
    );

    croatianPublications = (t) => (
        <>
            <div className="col-md-4 offset-md-2">
                <div className="Publications-section">
                    <div className="Publications-date">2020</div>

                    <h3 className="Publications-title">Kvaliteta usluga za beskućnike: Prikaz trenutnog stanja i izazova u Hrvatskoj.</h3>
                    <p>Šikić-Mićanović, L., Sakić S., Stelko, S.</p>

                    <Link to="/publications">{t('READ_MORE_BUTTON')} <span>&gt;</span></Link>
                </div>
            </div>
            <div className="col-md-4">
                <div className="Publications-section">
                    <div className="Publications-date">2020</div>

                    <h3 className="Publications-title">Who else needs protection? Reflecting on Researcher Vulnerability in Sensitive Research.</h3>
                    <p>Šikić-Mićanović, L., Sakić S., Stelko, S.</p>

                    <Link to="/publications">{t('READ_MORE_BUTTON')} <span>&gt;</span></Link>
                </div>
            </div>
        </>
    );

    render() {
        const {language, activities = []} = this.props;
        const t = getTranslations(language);

        const latestActivities = ActivityService.getLatest(activities);

        return (
            <div className="Home">

                {/* HERO IMAGE SECTION */}
                <div className="Home-hero">
                    <div className="Home-background"></div>
                    <div className="Home-text">
                        <h1>{t("HOME_TITLE_EXPLORING")} <em>{t("HOME_TITLE_HOMELESSNESS")}</em></h1>
                        <h2>{t("HOME_SUBTITLE")}</h2>
                    </div>
                    <div className="Home-explore">
                        <img src={arrows} className="Home-arrows" onClick={this.scrollToHeader} alt="Explore" />
                        <p className="Home-explore-btn" onClick={this.scrollToHeader}>{t("HOME_EXPLORE")}</p>
                    </div>
                </div>
                {/* HERO IMAGE SECTION END */}

                {/* ABOUT PROJECT SECTION */}
                <div className="Home-about">
                    <Title title={t("HOME_ABOUT_PROJECT")} subtitle={t("HOME_WHAT_WE_DO")} titleRef={this.headerRef}/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md">
                                <p className="About-emphasized">{t('HOME_ABOUT_PROJECT_MAIN_TEXT')}</p>
                                <p><strong>{t('HOME_SECONDARY_TEXT_BOLD')}</strong> {t('HOME_SECONDARY_TEXT_REST')}</p>
                                <p><strong>{t('HOME_SECONDARY_TEXT_BOLD_2')}</strong> {t('HOME_SECONDARY_TEXT_REST_2')}</p>
                                <Link to="/about" className="Btn-primary">{t('READ_MORE_BUTTON')}</Link>
                            </div>
                            <div className="col-md">
                                <img src={collaboration} className="Collaboration-img" alt="Croatian and Swiss collaboration" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* ABOUT PROJECT SECTION END */}

                {/* GOALS SECTION */}
                <div className="Home-goals">
                    <Title title={t('HOME_GOALS')} subtitle={t('HOME_GOALS_SUBTITLE')} />

                    {/* TODO - make each of these do something on click, i.e. go on different part of About page */}
                    <div className="container">
                        <div className="row">
                            <div className="col-md">
                                <div className="Goals-section">
                                    <img src={insiderPerspective} alt="Insider perspective" />
                                    <h3>{t('HOME_GOALS_1_TITLE')}</h3>
                                    <p>{t('HOME_GOALS_1_TEXT')}</p>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="Goals-section">
                                    <img src={strategies} alt="Inclusion strategies"/>
                                    <h3>{t('HOME_GOALS_2_TITLE')}</h3>
                                    <p>{t('HOME_GOALS_2_TEXT')}</p>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="Goals-section">
                                    <img src={cooperation} alt="Cooperation and collaboration" />
                                    <h3>{t('HOME_GOALS_3_TITLE')}</h3>
                                    <p>{t('HOME_GOALS_3_TEXT')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* GOALS SECTION END */}

                {/* CUSTOM SECTION */}
                <div className="Home-custom">
                    <div className="row no-gutters">
                        <div className="col-md">
                            <div className="Woman-img" />
                        </div>
                        <div className="col-md">
                            <div className="Custom-content">
                                {/* TODO - depending on the text below, it might cause problems with responsiveness */}
                                <h3>{t('HOME_IMAGE_TITLE')}</h3>
                                <div className="Custom-line"></div>
                                <p dangerouslySetInnerHTML={{__html: t('HOME_IMAGE_TEXT')}}></p>
                                <img src={dots} className="Dots-img" alt="Dots" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* CUSTOM SECTION END */}

                {/* ACTIVITIES SECTION */}
                <div className="Home-activities">
                    <Title title={t('HOME_ACTIVITIES')} subtitle={t('HOME_ACTIVITIES_SUBTITLE')} />
                    <p className="Activities-text">{t('HOME_ACTIVITIES_TEXT')}</p>

                    <div className="container">
                        <div className="row">
                            {latestActivities.map((activity, index) => (
                                <div key={index} className="col-md" onClick={() => this.openActivity(activity)}>
                                    <div className="Activities-section">
                                        <div className="Activities-date">{moment(activity.createdAt).utc().format('DD.MM.YYYY')}</div>
                                        <h3 className="Activities-title">{ActivityService.getTitle(activity, language)}</h3>

                                        {activity.image && (
                                            <img src={activity.image} alt={ActivityService.getTitle(activity, language)}/>
                                        )}

                                        {!activity.image && (
                                            <div className="Activity-no-image">
                                                <h1>HOMELESSNESS</h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <Link to="/activities" className="Btn-primary">{t('HOME_SEE_ALL_ACTIVITIES')}</Link>
                </div>
                {/* ACTIVITIES SECTION END */}

                {/* TESTIMONIALS SECTION */}
                <div className="Home-testimonials">
                    <div className="row no-gutters">
                        <div className="col-md">
                            <div className="Testimonials-img"></div>
                        </div>
                        <div className="col-md">
                            <div className="Custom-content">
                                <h3>{t('HOME_LIVES_MATTER_TITLE')}</h3>
                                <div className="Custom-line"></div>
                                <p dangerouslySetInnerHTML={{__html: t('HOME_LIVES_MATTER_TEXT')}}></p>
                                <img src={dotsTestimonials} className="Dots-img" alt="Dots" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* TESTIMONIALS SECTION END */}

                {/* PUBLICATIONS SECTION */}
                <div className="Home-publications">
                    <Title title={t('HOME_PUBLICATIONS')} subtitle={t('HOME_PUBLICATIONS_SUBTITLE')}/>
                    <p className="Publications-text">{t('HOME_PUBLICATIONS_TEXT')}</p>

                    <div className="container">
                        <div className="row">
                            {language === "en" && this.englishPublications(t)}
                            {language === "de" && this.germanPublications(t)}
                            {language === "hr" && this.croatianPublications(t)}
                        </div>
                    </div>

                    <Link to="/publications" className="Btn-primary">{t('HOME_SEE_ALL_PUBLICATIONS')}</Link>
                </div>
                {/* PUBLICATIONS SECTION END */}

                {/* TEAM SECTION */}
                <div className="Home-team">
                    <div className="Team-img"></div>

                    <div className="Team-content">
                        <Title title={t('HOME_MEET_THE_TEAM')} subtitle={t('HOME_MEET_THE_TEAM_SUBTITLE')}/>
                        <Link to="/team" className="Btn-primary">{t('CONTACT_US_BUTTON')}</Link>
                    </div>
                </div>
                {/* TEAM SECTION END */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {activities: {docs: activities}, ui: {language}} = state;
    let userActivities = activities;
    if (activities && !UserService.isLoggedIn()) {
        userActivities = activities.filter(activity => activity.languages.includes(language));
    }

    return {
        activities: userActivities,
        language
    }
};

export default connect(mapStateToProps)(Home);