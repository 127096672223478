import React, {Component} from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {Provider} from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import configureStore from '../configureStore';

import About from '../About/About';
import Activities from '../Activities/Activities';
import Activity from '../Activity/Activity';
import ActivityEdit from '../ActivityEdit/ActivityEdit';
import ActivityNew from '../ActivityNew/ActivityNew';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Home from '../Home/Home';
import Login from '../Login/Login';
import PageNotFound from '../PageNotFound/PageNotFound';
import Publications from '../Publications/Publications';
import ScrollToTop from '../_components/ScrollToTop/ScrollToTop';
import Team from '../Team/Team';

import './App.css';

const history = createBrowserHistory();
const store = configureStore(history);


class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <ScrollToTop />
                    <div className="App">
                        <Switch>
                            <Route exact path="/login" component={Login} />

                            <Route render={() => (
                                <>
                                    <Header/>
                                    <Switch>
                                        <Route exact path="/" component={Home} />
                                        <Route exact path="/about" component={About} />
                                        <Route exact path="/activities" component={Activities} />
                                        <Route exact path="/activities/new" component={ActivityNew} />
                                        <Route exact path="/activities/:activityId" component={Activity} />
                                        <Route exact path="/activities/:activityId/edit" component={ActivityEdit} />
                                        <Route exact path="/publications" component={Publications} />
                                        <Route exact path="/team" component={Team} />
                                        <Route component={PageNotFound}/>
                                    </Switch>
                                    <Footer/>
                                </>
                            )} />
                        </Switch>
                    </div>
                </ConnectedRouter>
            </Provider>
        );
    }
}

export default App;
