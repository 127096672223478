import React, {Component} from 'react';

import './ActivityEdit.css';

class ActivityEdit extends Component {

    render() {
        return (
            <div className="Activity">
                <header className="App-header">
                    <h1>Single activity edit</h1>
                </header>
            </div>
        )
    }
}

export default ActivityEdit;