import React, {Component} from 'react';
import { connect } from 'react-redux';

import {getTranslations} from "../_services/translations";

import './PageNotFound.css';

class PageNotFound extends Component {

    render() {
        const {language} = this.props;
        const t = getTranslations(language);

        return (
            <div className="PageNotFound">
                <h1>
                    404
                </h1>
                <h2>
                    {t('PAGE_DOES_NOT_EXIST')}
                </h2>
                <a href="/" className="Btn-primary">
                    {t('BACK_TO_HOME')}
                </a>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    language: state.ui.language,
});

export default connect(mapStateToProps)(PageNotFound);