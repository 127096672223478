import {push} from 'connected-react-router';
import moment from 'moment';
import React, {Component} from 'react';
import {connect} from "react-redux";
import {deleteActivity, getActivities, getMostPopularActivities} from "../_actions/activities";
import {getTranslations} from "../_services/translations";
import ActivityService from "../_services/activities";
import UserService from '../_services/user';

import './Activities.css';

class Activities extends Component {

    componentDidMount() {
        const {dispatch, page = 1} = this.props;
        dispatch(getActivities(page));
        dispatch(getMostPopularActivities());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {page: prevPage = 1} = prevProps;
        const {dispatch, page: newPage = 1} = this.props;

        if (prevPage !== newPage) {
            dispatch(getActivities(newPage));
        }
    }

    openActivity = (activity) => {
        const {dispatch} = this.props;
        return dispatch(push(`/activities/${activity.id}`));
    };

    deleteActivity = (activityId) => {
        const {dispatch} = this.props;
        return dispatch(deleteActivity(activityId));
    };

    getPaginationGroup = ({page, totalPages}) => {
        return [page - 2, page - 1, page, page + 1, page + 2].filter(x => x > 0 && x <= totalPages);
    };

    openPage = (page) => {
        const {dispatch} = this.props;
        return dispatch(push(`/activities?page=${page}`));
    };

    render() {
        const {isFetching, activities = [], popular = [], language, pagination} = this.props;

        // TODO - add loader
        if (isFetching || !activities) {
            return null;
        }

        const t = getTranslations(language);

        return (
            <div className="Activities">
                <div className="row">
                    <div className="col-md-3">
                        <div className="Activities-popular">
                            <h2>{t('ACTIVITIES_MOST_POPULAR')}</h2>

                            {popular.map((activity, index) => (
                                <div
                                    key={index}
                                    className="Activities-recent-item"
                                    onClick={() => this.openActivity(activity)}
                                >
                                    {activity.image && (
                                        <img
                                            src={activity.image}
                                            alt={ActivityService.getTitle(activity, language)}
                                            width="100%"
                                            height="auto"/>
                                    )}
                                    {!activity.image && (
                                        <div className="Activity-no-image">
                                            <h1>HOMELESSNESS</h1>
                                        </div>
                                    )}
                                    <h3>{ActivityService.getTitle(activity, language)}</h3>
                                    <p>{moment(activity.createdAt).utc().format('DD.MM.YYYY')}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="Activities-list">
                            <h2>{t('ACTIVITIES').toUpperCase()}</h2>
                            <div className="Activities-navigation"><a href="/">{t('HOME')}</a> / {t('ACTIVITIES')}</div>
                            <p>{t('ACTIVITIES_SUBTITLE')}</p>

                            {UserService.isLoggedIn() && (
                                <a href="/activities/new" className="Btn-secondary">
                                    + NEW ACTIVITY
                                </a>
                            )}

                            {activities.map((activity, index) => (
                                <div key={index} className="Activities-list-item">
                                    <div className="row">
                                        <div className="col-5">
                                            {activity.image && (
                                                <img
                                                    src={activity.image}
                                                    alt={ActivityService.getTitle(activity, language)}
                                                    width="100%"
                                                    height="auto"
                                                    onClick={() => this.openActivity(activity)}
                                                />
                                            )}
                                            {!activity.image && (
                                                <div className="Activity-no-image"
                                                     onClick={() => this.openActivity(activity)}>
                                                    <h1>HOMELESSNESS</h1>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-7">
                                            <h1 onClick={() => this.openActivity(activity)}>{ActivityService.getTitle(activity, language)}</h1>
                                            <div className="hr"></div>
                                            <div className="Activities-list-details">
                                                <span>{moment(activity.createdAt).utc().format('DD.MM.YYYY')}</span>
                                                <span className="dot"></span>
                                                <span>by {activity.author.name}</span>
                                                <span className="dot"></span>
                                                <span
                                                    className="Activities-list-type">{t(activity.type.toUpperCase())}</span>
                                            </div>
                                            <div className="Activities-list-buttons">
                                                <a href={`/activities/${activity.id}`}
                                                   className="Btn-primary">{t('READ_MORE_BUTTON')}</a>

                                                {/*<a href={`/activities/edit/${activity.id}`} className="Btn-secondary">{t('EDIT_BUTTON')}</a>*/}

                                                {UserService.isLoggedIn() && (
                                                    <button onClick={() => this.deleteActivity(activity.id)}
                                                            className="Btn-secondary">{t('DELETE_BUTTON')}</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="Activities-pagination">
                                <span
                                    className={pagination.page === 1 ? "disabled" : ""}
                                    onClick={() => pagination.page > 1 && this.openPage(pagination.page - 1)}
                                >&lt;</span>
                                {this.getPaginationGroup(pagination).map((page, index) => (
                                    <span
                                        key={index}
                                        className={pagination.page === page ? "active": ""}
                                        onClick={() => this.openPage(page)}
                                    >
                                        {page}
                                    </span>
                                ))}
                                <span
                                    className={pagination.page === pagination.totalPages ? "disabled": ""}
                                    onClick={() => pagination.page < pagination.totalPages && this.openPage(pagination.page + 1)}
                                >&gt;</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        activities: {isFetching, docs: activities, popular, ...pagination},
        ui: {language},
        router: {location: {query: {page} = {}} = {}} = {}
    } = state;

    let userActivities = activities;
    if (activities && !UserService.isLoggedIn()) {
        userActivities = activities.filter(activity => activity.languages.includes(language));
    }

    if (userActivities) {
        userActivities.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    }

    return {
        isFetching,
        activities: userActivities,
        popular,
        language,
        page,
        pagination
    }
};

export default connect(mapStateToProps)(Activities);