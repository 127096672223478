import React, {Component} from 'react';
import './Title.css';

class Title extends Component {

    render() {
        const { title = '', subtitle = '', titleRef } = this.props;

        return (
            <div className="Title" ref={titleRef}>
                <h2 className="Title-main">{title}</h2>
                {subtitle && (<p className="Title-subtitle">{subtitle}</p>)}
                <div className="Title-line"></div>
            </div>
        )
    }
}

export default Title;