import activitiesConstants from '../_constants/activities';

const initialActivitiesState = {
    isFetching: false,
    docs: [],
    popular: [],
    error: undefined
};

export function activities(state = initialActivitiesState, action) {
    switch (action.type) {
        case activitiesConstants.GET_REQUEST:
        case activitiesConstants.GET_ACTIVITY_REQUEST:
        case activitiesConstants.GET_MOST_POPULAR_REQUEST:
        case activitiesConstants.DELETE_REQUEST:
            return {
                ...state,
                isFetching: true
            };

        case activitiesConstants.GET_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: undefined,
                ...action.activities
            };

        case activitiesConstants.GET_ACTIVITY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: undefined,
                docs: [action.activity]
            };

        case activitiesConstants.GET_MOST_POPULAR_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: undefined,
                popular: action.activities
            }

        case activitiesConstants.DELETE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: undefined,
                docs: state.docs.filter(activity => activity.id !== action.activityId)
            };

        case activitiesConstants.GET_FAILURE:
        case activitiesConstants.DELETE_FAILURE:
            return {
                ...state,
                isFetching: false,
                docs: [],
                error: action.error
            };

        case activitiesConstants.GET_MOST_POPULAR_FAILURE:
            return {
                ...state,
                isFetching: false,
                popular: [],
                error: action.error
            };
        default:
            return state;
    }
}
