import clone from "lodash/clone";
import get from "lodash/get";
import UserService from './user';

class ActivityService {

    async get(page = null) {
        let url = "/api/activities";
        if (page) {
            url += `?page=${page}`;
        }
        return (await fetch(url)).json();
    }

    async getOne(activityId) {
        return (await fetch(`/api/activities/${activityId}`)).json();
    }

    async getMostPopular() {
        return (await fetch('/api/activities/popular')).json();
    }

    async create(data) {
        const response = await fetch('/api/activities', {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
               'Authorization': UserService.getToken()
           },
           body: JSON.stringify(data)
        });

        return response.json();
    }

    async delete(activityId) {
        const response = await fetch(`/api/activities/${activityId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': UserService.getToken()
            }
        });

        return response.json();
    }

    getLatest(activities) {
        if(!activities) {
            return [];
        }

        return clone(activities)
            .filter(activity => activity.type === 'project_activity')
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 3);
    }

    getTitle(activity, language) {
        return get(activity, `title.${language}`) ||
            ('*' +
                (get(activity, 'title.en') ||
                get(activity, `title.${get(activity, 'languages[0]')}`))
            );
    }
}

export default new ActivityService();