import {translations} from "../_constants/translations";

const t = (lang) => (term) => {
    const trans = translations[lang] || translations["en"];
    if (trans[term] === "") {
        return "";
    }
    return trans[term] || term;
}

export const getTranslations = (lang) => t(lang);
