const activitiesConstants = {
    GET_REQUEST: 'GET_ACTIVITIES_REQUEST',
    GET_SUCCESS: 'GET_ACTIVITIES_SUCCESS',
    GET_FAILURE: 'GET_ACTIVITIES_FAILURE',

    GET_MOST_POPULAR_REQUEST: 'GET_MOST_POPULAR_REQUEST',
    GET_MOST_POPULAR_SUCCESS: 'GET_MOST_POPULAR_SUCCESS',
    GET_MOST_POPULAR_FAILURE: 'GET_MOST_POPULAR_FAILURE',

    GET_ACTIVITY_REQUEST: 'GET_ACTIVITY_REQUEST',
    GET_ACTIVITY_SUCCESS: 'GET_ACTIVITY_SUCCESS',
    GET_ACTIVITY_FAILURE: 'GET_ACTIVITY_FAILURE',

    CREATE_REQUEST: 'CREATE_REQUEST',
    CREATE_SUCCESS: 'CREATE_SUCCESS',
    CREATE_FAILURE: 'CREATE_FAILURE',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',
};

export default activitiesConstants;
