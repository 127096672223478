
class UIService {

    getLanguage() {
        return localStorage.getItem("language") || "en";
    }

    setLanguage(language = "en") {
        localStorage.setItem("language", language);
    }

}

export default new UIService();