const uiConstants = {
    LANGUAGE_CHANGED: 'LANGUAGE_CHANGED',
};

export const languages = {
    en: "English",
    hr: "Hrvatski",
    de: "Deutsch",
}

export default uiConstants;
