import userConstants from '../_constants/user';

const initialUserState = {
    isFetching: false,
    data: undefined,
    error: undefined
};

export function user(state = initialUserState, action) {
    switch (action.type) {
        case userConstants.LOGIN:
            return {
                ...state,
                isFetching: true
            };
        default:
            return state;
    }
}
