import {push} from "connected-react-router";
import moment from "moment";
import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';

import Title from '../_components/Title/Title';
import lyn from '../_images/team/lynette-sikic-micanovic.jpg';
import matthias from '../_images/team/matthias-drilling.jpg';
import pin from '../_images/pin.svg';
import logo1 from '../_images/fns-swiss-logo.svg';
import logo2 from '../_images/hrzz_logo.svg';
import logo3 from '../_images/swiss-croatian-collaboration-logo.svg';

import {getMostPopularActivities} from "../_actions/activities";
import ActivityService from "../_services/activities";
import {getTranslations} from "../_services/translations";

import './About.css';

class About extends Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getMostPopularActivities());
    }

    openActivity = (activity) => {
        const { dispatch } = this.props;
        return dispatch(push(`/activities/${activity.id}`));
    };

    englishCollaborators = (t) => (
        <>
            <div className="Collaborators-links">
                <h3>{t('ABOUT_ORGANIZATIONS_INTERNATIONAL')}</h3>

                <div>EU-COST Action CA15218 (Belgium)</div>
                <a href="https://www.cost.eu/actions/CA15218/#tabs|Name:overview" target="__blank" rel="noopener noreferrer" >
                    https://www.cost.eu/actions/CA15218/#tabs|Name:overview
                </a>

                <div>International Network of Street papers INSP (Great Britain and North Ireland)</div>
                <a href="https://insp.ngo/" target="__blank" rel="noopener noreferrer" >
                    https://insp.ngo
                </a>
            </div>

            <div className="Collaborators-links">
                <h3>{t('ABOUT_ORGANIZATIONS_SWITZERLAND')}</h3>

                <div>Department of Social Welfare in Bern (Switzerland)</div>
                <a href="https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss" target="__blank" rel="noopener noreferrer" >
                    https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss
                </a>

                <div>PINTO Mobile Team in Bern (Switzerland)</div>
                <a href="https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss/familieundquartier/pinto-pravention-intervention-toleranz" target="__blank" rel="noopener noreferrer" >
                    https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss/familieundquartier/pinto-pravention-intervention-toleranz
                </a>

                <div>‘Soup & Chill’ Soup Kitchen in Basel (Switzerland)</div>
                <a href="https://www.soupandchill.com/" target="__blank" rel="noopener noreferrer" >
                    https://www.soupandchill.com
                </a>

                <div>Treffpunkt Glaibasel in Basel (Switzerland)</div>
                <a href="http://treffpunktglaibasel.ch/" target="__blank" rel="noopener noreferrer" >
                    http://treffpunktglaibasel.ch
                </a>
            </div>

            <div className="Collaborators-links">
                <h3>{t('ABOUT_ORGANIZATIONS_CROATIA')}</h3>

                <div>Croatian Network for Homeless People (Croatia)</div>
                <a href="http://beskucnici.info/" target="__blank" rel="noopener noreferrer" >
                    http://beskucnici.info
                </a>


                <div>MoST, NGO in Split (Croatia)</div>
                <a href="https://www.most.hr/" target="__blank" rel="noopener noreferrer" >
                    https://www.most.hr/
                </a>

                <div>HELP, NGO in Split (Croatia)</div>
                <a href="http://helponline.hr/" target="__blank" rel="noopener noreferrer" >
                    http://helponline.hr
                </a>

                <div>RCCG Home of Hope, NGO in Zagreb (Croatia)</div>
                <a href="https://domnade.wordpress.com/" target="__blank" rel="noopener noreferrer" >
                    https://domnade.wordpress.com
                </a>

                <div>The Society of St. Vincent de Paul</div>

                <div>NGO Savao</div>
                <a href="https://web.facebook.com/HUSavao" target="__blank" rel="noopener noreferrer" >
                    https://web.facebook.com/HUSavao
                </a>

                <div>Brodoto</div>
                <a href="https://www.brodoto.com/" target="__blank" rel="noopener noreferrer" >
                    https://www.brodoto.com/
                </a>
            </div>
        </>
    );

    germanCollaborators = (t) => (
        <>
            <div className="Collaborators-links">
                <h3>{t('ABOUT_ORGANIZATIONS_INTERNATIONAL')}</h3>

                <div>Internationales Netzwerk der Strassenzeitungen (Grossbritannien und Nordirland)</div>
                <a href="https://insp.ngo/" target="__blank" rel="noopener noreferrer" >
                    https://insp.ngo
                </a>
            </div>

            <div className="Collaborators-links">
                <h3>{t('ABOUT_ORGANIZATIONS_SWITZERLAND')}</h3>

                <div>Städtischer Sozialdienst, Bern (Schweiz)</div>
                <a href="https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss" target="__blank" rel="noopener noreferrer" >
                    https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss
                </a>

                <div>Pinto - Interdisziplinäres Team, Bern (Schweiz)</div>
                <a href="https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss/familieundquartier/pinto-pravention-intervention-toleranz" target="__blank" rel="noopener noreferrer" >
                    https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss/familieundquartier/pinto-pravention-intervention-toleranz
                </a>

                <div>Soup & Chill, Basel (Schweiz)</div>
                <a href="https://www.soupandchill.com/" target="__blank" rel="noopener noreferrer" >
                    https://www.soupandchill.com
                </a>

                <div>Treffpunkt Glaibasel, Basel (Schweiz)</div>
                <a href="http://treffpunktglaibasel.ch/" target="__blank" rel="noopener noreferrer" >
                    http://treffpunktglaibasel.ch
                </a>
            </div>

            <div className="Collaborators-links">
                <h3>{t('ABOUT_ORGANIZATIONS_CROATIA')}</h3>

                <div>Kroatisches Netzwerk für Obdachlose (Kroatien)</div>
                <a href="http://beskucnici.info/" target="__blank" rel="noopener noreferrer" >
                    http://beskucnici.info
                </a>


                <div>MoST, NGO in Split (Kroatien)</div>
                <a href="https://www.most.hr/" target="__blank" rel="noopener noreferrer" >
                    https://www.most.hr/
                </a>

                <div>HELP, NGO in Split (Kroatien)</div>
                <a href="http://helponline.hr/" target="__blank" rel="noopener noreferrer" >
                    http://helponline.hr
                </a>

                <div>RCCG Home of Hope, NGO in Zagreb (Kroatien)</div>
                <a href="https://domnade.wordpress.com/" target="__blank" rel="noopener noreferrer" >
                    https://domnade.wordpress.com
                </a>

                <div>Die Gesellschaft des Heiligen Vinzenz von Paul</div>

                <div>NGO Savao</div>
                <a href="https://web.facebook.com/HUSavao" target="__blank" rel="noopener noreferrer" >
                    https://web.facebook.com/HUSavao
                </a>

                <div>Brodoto</div>
                <a href="https://www.brodoto.com/" target="__blank" rel="noopener noreferrer" >
                    https://www.brodoto.com/
                </a>
            </div>
        </>
    );

    croatianCollaborators = (t) => (
        <>
            <div className="Collaborators-links">
                <h3>{t('ABOUT_ORGANIZATIONS_INTERNATIONAL')}</h3>

                <div>EU-COST Action CA15218 (Belgium)</div>
                <a href="https://www.cost.eu/actions/CA15218/#tabs|Name:overview" target="__blank" rel="noopener noreferrer" >
                    https://www.cost.eu/actions/CA15218/#tabs|Name:overview
                </a>

                <div>International Network of Street papers INSP (Great Britain and North Ireland)</div>
                <a href="https://insp.ngo/" target="__blank" rel="noopener noreferrer" >
                    https://insp.ngo
                </a>
            </div>

            <div className="Collaborators-links">
                <h3>{t('ABOUT_ORGANIZATIONS_SWITZERLAND')}</h3>

                <div>Department of Social Welfare in Bern (Switzerland)</div>
                <a href="https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss" target="__blank" rel="noopener noreferrer" >
                    https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss
                </a>

                <div>PINTO Mobile Team in Bern (Switzerland)</div>
                <a href="https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss/familieundquartier/pinto-pravention-intervention-toleranz" target="__blank" rel="noopener noreferrer" >
                    https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/bss/familieundquartier/pinto-pravention-intervention-toleranz
                </a>

                <div>‘Soup & Chill’ Soup Kitchen in Basel (Switzerland)</div>
                <a href="https://www.soupandchill.com/" target="__blank" rel="noopener noreferrer" >
                    https://www.soupandchill.com
                </a>

                <div>Treffpunkt Glaibasel in Basel (Switzerland)</div>
                <a href="http://treffpunktglaibasel.ch/" target="__blank" rel="noopener noreferrer" >
                    http://treffpunktglaibasel.ch
                </a>
            </div>

            <div className="Collaborators-links">
                <h3>{t('ABOUT_ORGANIZATIONS_CROATIA')}</h3>

                <div>Hrvatska mreža za beskućnike</div>
                <a href="http://beskucnici.info/" target="__blank" rel="noopener noreferrer" >
                    http://beskucnici.info
                </a>


                <div>MoST, Split</div>
                <a href="https://www.most.hr/" target="__blank" rel="noopener noreferrer" >
                    https://www.most.hr/
                </a>

                <div>HELP, Split</div>
                <a href="http://helponline.hr/" target="__blank" rel="noopener noreferrer" >
                    http://helponline.hr
                </a>

                <div>RCCG Dom nade, Zagreb</div>
                <a href="https://domnade.wordpress.com/" target="__blank" rel="noopener noreferrer" >
                    https://domnade.wordpress.com
                </a>

                <div>Družba sestara milosrdnica sv. Vinka Paulskoga, Zagreb</div>

                <div>Humanitarna udruga “Savao”, Zagreb</div>
                <a href="https://web.facebook.com/HUSavao" target="__blank" rel="noopener noreferrer" >
                    https://web.facebook.com/HUSavao
                </a>

                <div>Brodoto</div>
                <a href="https://www.brodoto.com/" target="__blank" rel="noopener noreferrer" >
                    https://www.brodoto.com/
                </a>
            </div>
        </>
    );

    render() {
        const {language, popular = [] } = this.props;
        const t = getTranslations(language);

        return (
            <div className="About">
                {/* HEADER SECTION */}
                <div className="About-header">
                    <div className="container">
                        <div className="Header-container">
                            <div>
                                <h1>{t('ABOUT').toUpperCase()}</h1>
                                <p>{t('ABOUT_SUBTITLE')}</p>
                            </div>
                            <div><a href="/">{t('HOME')}</a> / {t('ABOUT')}</div>
                        </div>
                    </div>
                </div>
                {/* HEADER SECTION END */}

                {/* ABOUT PROJECT SECTION*/}
                <div className="About-project">
                    <Title title={t('ABOUT_PROJECT')} subtitle={t('ABOUT_PROJECT_SUBTITLE')}/>
                    <p>{t('ABOUT_PROJECT_TEXT')}</p>
                </div>
                {/* ABOUT PROJECT SECTION END */}

                {/* WHO CONDUCTS SECTION */}
                <div className="About-researchers">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg">
                                <div className="Researchers-images">
                                    <div>
                                        <img src={matthias} alt="Mary Jane" />
                                        <span>Prof. Dr. Matthias Drilling</span>
                                    </div>
                                    <div>
                                        <img src={lyn} alt="Mary Jane" />
                                        <span>Dr. Lynette Šikić Mićanović</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg">
                                <div className="Researchers-text">
                                    <h3>{t('ABOUT_WHO_WILL_CONDUCT')}</h3>
                                    <p>{t('ABOUT_WHO_WILL_CONDUCT_TEXT')}</p>
                                    <h3>{t('ABOUT_WHO_APPROVED')}</h3>
                                    <p>{t('ABOUT_WHO_APPROVED_TEXT')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* WHO CONDUCTS SECTION END */}

                {/* WHAT IS INVOLVED SECTION */}
                <div className="About-purpose">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg">
                                <div className="Purpose-text">
                                    <h3>{t('ABOUT_WHAT_IS_INVOLVED')}</h3>
                                    <p>{t('ABOUT_WHAT_IS_INVOLVED_TEXT')}</p>
                                    <h3>{t('ABOUT_WHAT_IS_THE_PURPOSE')}</h3>
                                    <p>{t('ABOUT_WHAT_IS_THE_PURPOSE_TEXT')}</p>
                                </div>
                            </div>
                            <div className="col-lg">
                                <div className="Purpose-locations">
                                    <div className="Location-box">
                                        <img src={pin} alt="Location pin"/>
                                        <span className="Location-city">Bern</span>
                                        <span className="Location-country">{t('SWITZERLAND')}</span>
                                    </div>
                                    <div className="Location-box">
                                        <img src={pin} alt="Location pin"/>
                                        <span className="Location-city">Basel</span>
                                        <span className="Location-country">{t('SWITZERLAND')}</span>
                                    </div>
                                    <div className="Location-box">
                                        <img src={pin} alt="Location pin"/>
                                        <span className="Location-city">Zagreb</span>
                                        <span className="Location-country">{t('CROATIA')}</span>
                                    </div>
                                    <div className="Location-box">
                                        <img src={pin} alt="Location pin"/>
                                        <span className="Location-city">Split</span>
                                        <span className="Location-country">{t('CROATIA')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* WHAT IS INVOLVED SECTION END*/}

                {/* NUMBERS SECTION */}
                <div className="About-numbers">
                    <div className="container">
                        <div className="row">
                            <div className="col-md">
                                <div className="Numbers-text">
                                    <div className="Numbers-number">4</div>
                                    <div>{t('ABOUT_CITIES')}</div>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="Numbers-text">
                                    <div className="Numbers-number">3</div>
                                    <div>{t('ABOUT_YEARS')}</div>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="Numbers-text">
                                    <div className="Numbers-number">13</div>
                                    <div>{t('ABOUT_PARTICIPATING_ORGANIZATIONS')}</div>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="Numbers-text">
                                    <div className="Numbers-number">170+</div>
                                    <div>{t('ABOUT_INTERVIEWS')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* NUMBERS SECTION END */}

                {/* SUPPORTERS SECTION*/}
                <div className="About-supporters">
                    <div className="container">
                        <Title title={t('ABOUT_SUPPORTERS')} />
                        <div className="Supporters-logos">
                            <div className="row">
                                <div className="col-md">
                                    <img src={logo3} alt="Swiss-Croatian Cooperation Programme" />
                                </div>
                                <div className="col-md">
                                    <img src={logo1} alt="Swiss national science foundation" />
                                </div>
                                <div className="col-md">
                                    <img src={logo2} alt="Hrvatska zaklada za znanost" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* SUPPORTERS SECTION END */}

                {/* COLLABORATORS SECTION */}
                <div className="About-collaborators">
                    <div className="container">
                    <Title title={t('ABOUT_PARTICIPATING_ORGANIZATIONS_TITLE')} subtitle={t('ABOUT_SUPPORTERS_SUBTITLE')} />

                        {language === "en" && this.englishCollaborators(t)}
                        {language === "de" && this.germanCollaborators(t)}
                        {language === "hr" && this.croatianCollaborators(t)}


                    </div>
                </div>
                {/* COLLABORATORS SECTION END */}

                {/* RECENT ACTIVITIES SECTION */}
                <div className="About-activities">
                    <h3>{t('ABOUT_SELECTED_ACTIVITIES')}</h3>

                    <div className="container">
                        <div className="row">
                            {popular.map((activity, index) => (
                                <div key={index} className="col-md" onClick={() => this.openActivity(activity)}>
                                    <div className="Activities-section">
                                        <div className="Activities-date">{moment(activity.createdAt).utc().format('DD MMM. YYYY').replace('May.', 'May')}</div>
                                        <h3 className="Activities-title">{ActivityService.getTitle(activity, language)}</h3>

                                        {activity.image && (
                                            <img src={activity.image} alt="Activity" />
                                        )}

                                        {!activity.image && (
                                            <div className="Activity-no-image">
                                                <h1>HOMELESSNESS</h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <Link to="/activities" className="Btn-primary">
                        {t('ABOUT_SEE_ALL_ACTIVITIES')}
                    </Link>
                </div>
                {/* RECENT ACTIVITIES SECTION END */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {activities: {popular}, ui: {language}} = state;

    return {
        popular,
        language
    }
};

export default connect(mapStateToProps)(About);